import { FaRegTrashAlt } from "react-icons/fa";

function SelectedInputs({ selected_inputs, onRemoveSelectedValue, all_values = false }) {
  return (
    <div className={`selected-inputs ${all_values ? "flex" : ""}`}>
      {selected_inputs.map((input_tag) => (
        <div className="input-tag" key={input_tag}>
          <p>{input_tag}</p>
          <FaRegTrashAlt onClick={() => onRemoveSelectedValue(input_tag)} className="input-tag-trash" />
        </div>
      ))}
    </div>
  );
}

export default SelectedInputs;