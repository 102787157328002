function FilterDoubleInput({label, name, placeholder, value, onChange, label2, name2, placeholder2, value2, onChange2}) {
  return (
    <div className="filter-input">
        <label>{label}</label>
        <div className="double-filter">
            <input type="text" name={name} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
            <input type="text" name={name2} placeholder={placeholder2} value={value2} onChange={(e) => onChange2(e.target.value)} />
        </div>
    </div>
  )
}

export default FilterDoubleInput

