import { useState, useEffect } from 'react'
import { FaSignInAlt, FaRegUser, FaSignOutAlt, FaUser, FaPlus, FaRegStar, FaEdit } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import logo from '../images/logo.png';

function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const [userType, setUserType] = useState(null)

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/')
  }

  useEffect(() => {
    if (user) {
      console.log(user)
      setUserType(user.type)
    }
  }, [user])

  return (
    <header className='header'>
      <div className="logo">
        <Link to='/'>
          <img src={logo} alt='logo' />
        </Link>
      </div>
      <div className="navigation">

          {user && userType === "admin"? (
            <div className='nav-items'>
            <Link to='/pieteikums'>
              <FaPlus /> Pievienot
            </Link>
            <Link to='/profiles'>
              <FaUser /> Profili
            </Link>
            <Link to='/projekti'>
              <FaRegStar /> Projekti
            </Link>
            <Link to='/unverified'>
              <FaRegUser /> Pieteikumi 
            </Link>
            <Link to='/classifiers'>
              <FaEdit /> Autofill
            </Link>
            <Link to='/' onClick={onLogout}>
              <FaSignOutAlt style={{marginRight: "10px", marginBottom: "-2px"}}/> Iziet 
            </Link>
            </div>
          ) : 
          user && userType === "producents" ? 
          <div className="flex gap-40">
          <Link to='/profiles'>
                          <FaUser style={{marginRight: "10px", marginBottom: "-2px"}}/> Profili 
                        </Link>
          <Link to='/' onClick={onLogout}>
                        <FaSignOutAlt style={{marginRight: "10px", marginBottom: "-2px"}}/> Iziet 
                      </Link>
</div>
            
              
              
          :
            <Link to='/pieteikums'>
                <FaSignInAlt style={{marginRight: "10px", marginBottom: "-2px"}}/> Pieteikties 
              </Link>
          }


      </div>
    </header>
  )
}

export default Header