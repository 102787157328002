import axios from 'axios'

const API_URL = '/classifiers/'

// Get classifier category values
const getValues = async (category) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // }
  const url = API_URL + category
  const response = await axios.get(url)//get(url, config)
  return response.data
}

const getCategories = async () => {
  const response = await axios.get(API_URL)
  return response.data
}

const deleteValue = async (category, value) => {
  const url = API_URL + category + '/' + value
  const response = await axios.delete(url)
  return response.data
}


const classifierService = {
  getValues,
  getCategories,
  deleteValue
}

export default classifierService