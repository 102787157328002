import { useEffect } from 'react';
import InputSelection from './input_helpers/InputSelection';

function KontaktuDati({ setValue, person, features, transformFeature }) {

  // useEffect(() => {
  //   if (person) {
  //     setValue('my_phone_code', person.my_phone_code || '', 'person');
  //     setValue('my_phone', person.my_phone || '', 'person');
  //     setValue('email', person.email || '', 'person');
  //     setValue('home_address', person.home_address || '', 'person');
  //   }
  // }, [person, setValue]);

  // useEffect(() => {
  //   if (features) {
  //     setValue('city', transformFeature(features.city) || '', 'features');
  //   }
  // }, [features, setValue, transformFeature]);

  return (
    <div>
      <div className="input-form-container padding-20-top">
        <h2>Kontaktinformācija</h2>
        <form className='input-form padding-10-top'>
          <div className="input-form-row">
            <div className="form-input width-25">
              <label>Telefona kods*</label>
              <input
                defaultValue={person ? person.my_phone_code : ''}
                type="number"
                name="country_code"
                placeholder="+371"
                onChange={(e) => setValue('my_phone_code', e.target.value, 'person')}
              />
            </div>
            <div className="form-input width-75">
              <label>Telefona numurs*</label>
              <input
                defaultValue={person ? person.my_phone : ''}
                type="tel"
                name="phone_number"
                placeholder="Telefona Numurs"
                onChange={(e) => setValue('my_phone', e.target.value, 'person')}
              />
            </div>
          </div>
          <div className="form-input">
            <label>E-pasta adrese*</label>
            <input
              defaultValue={person ? person.email : ''}
              className='person_email'
              type="email"
              name="email"
              placeholder="E-pasta adrese"
              onChange={(e) => setValue('email', e.target.value, 'person')}
            />
          </div>

          <div className="form-input">
            <label>Faktiskā adrese*</label>
            <input
              defaultValue={person ? person.home_address : ''}
              className='person_address'
              type="text"
              name="address"
              placeholder="Faktiskā adrese"
              onChange={(e) => setValue('home_address', e.target.value, 'person')}
            />
          </div>

          <div className="form-input">
            <label>Pilsēta*</label>
            <InputSelection
              name="city"
              placeholder="Pilsēta"
              setValue={(value) => setValue('city', value, 'features')}
              editValue={features ? features.city : null}
            />
          </div>
          <div className="form-group contact"></div>
        </form>
      </div>
    </div>
  )
}

export default KontaktuDati;
