import { useEffect } from 'react';

function GimenesDati({ setValue, person, birthdate }) {

  // useEffect(() => {
  //   if (person) {
  //     setValue('parent_name', person.parent_name || '', 'person');
  //     setValue('parent_phone_code', person.parent_phone_code || '', 'person');
  //     setValue('parent_phone', person.parent_phone || '', 'person');
  //   }
  // }, [person, setValue]);

  const olderThan18 = (birthdate) => {
    if (birthdate) {
      const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age > 18;
    }
    return false;
  }

  return (
    <div>
      <div className="input-form-container padding-20-top">
        <h2>Ģimenes Dati</h2>
        <p className="input-form-p">Personām, jaunākām par 18. gadiem norādīt arī vecāku vārdu uzvārdu un tālruņa numuru.</p>
        <form className='input-form padding-10-top'>
          <div className="input-form-row">
            <div className="form-input width-45">
              <label>Vēcaka vārds/uzvārds*</label>
              <input
                defaultValue={person ? person.parent_name : ''}
                className='parent_name'
                type="text"
                name="parent_name"
                placeholder={birthdate ? olderThan18(birthdate) ? "Vecāka vārds/uzvārds" : "Vecāka vārds/uzvārds*" : "Vecāka vārds/uzvārds"}
                onChange={(e) => setValue('parent_name', e.target.value, 'person')}
              />
            </div>
            <div className="form-input width-25">
              <label>Telefona kods*</label>
              <input
                defaultValue={person ? person.parent_phone_code : ''}
                className='parent_phone_code'
                type="text"
                name="parent_country_code"
                placeholder="+371"
                onChange={(e) => setValue('parent_phone_code', e.target.value, 'person')}
              />
            </div>
            <div className="form-input width-30">
              <label>Telefona numurs*</label>
              <input
                defaultValue={person ? person.parent_phone : ''}
                className='parent_phone'
                type="text"
                name="parent_phone_number"
                placeholder="Telefona Numurs"
                onChange={(e) => setValue('parent_phone', e.target.value, 'person')}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default GimenesDati;
