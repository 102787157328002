import { useEffect } from 'react';
import InputTagSelection from './input_helpers/InputTagSelection';

function VelmesDati({ features, setValue, transformFeature }) {

  // useEffect(() => {
  //   if(features){
  //     setValue('want_participate', transformFeature(features.want_participate) || '', 'features');
  //     setValue('dont_want_participate', transformFeature(features.dont_want_participate) || '', 'features');
  //     setValue('interested_in', transformFeature(features.interested_in) || '', 'features');
  //   }
  // }, [features, setValue, transformFeature]);

  return (
    <div>
      <div className="input-form-container padding-20-top">
          <h2>Manas vēlmes</h2>
          <form className="input-form padding-10-top">
            <div className="form-input">
              <label htmlFor="want_participate">Gribu piedalīties:</label>
              <InputTagSelection name="want_participate" placeholder="Gribu piedalīties" setValues={(values) => setValue('want_participate', values, 'features')} editValues={features ? features.want_participate : null} />
            </div>
            <div className="form-input">
              <label htmlFor="dont_want_participate">Negribu piedalīties:</label>
              <InputTagSelection name="dont_want_participate" placeholder="Negribu piedalīties" setValues={(values) => setValue('dont_want_participate', values, 'features')} editValues={features ? features.dont_want_participate : null} />
            </div>
            <div className="form-input">
              <label htmlFor="interested_in">Mani interesē:</label>
              <InputTagSelection name="interested_in" placeholder="Mani interesē" setValues={(values) => setValue('interested_in', values, 'features')} editValues={features ? features.interested_in : null} />
            </div>
          </form>
      </div>
    </div>
  );
}

export default VelmesDati;
