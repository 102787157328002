function FilterSelectInput({label, value, onChange, options}) {
    return (
        <div className="filter-input">
            <label>{label}</label>
            <select value={value} onChange={(e) => onChange(e.target.value)} className="input-filter">
                {options.map(option => 
                    <option key={option.value} value={option.value} disabled={option.label === "-"}>{option.label}</option>
                )}
            </select>
        </div>
    );
}

export default FilterSelectInput;