import { Link } from 'react-router-dom'
import ProjectCard from './ProjectCard'

function ProjectsList({projects}) {
  return (
    <div className="projects-list padding-20-top">
        {projects && projects.length > 0 ? 
            <>
                <h3 className="padding-20-bottom">Projektu Saraksts:</h3>
                <div className="projects-grid">
                        {projects.map((project, index) =>(
                            <ProjectCard key={index} project={project}/>
                        ))}
                </div>
            </>  
        : 
            <p>Pagaidām nav izveidots neviens projekts.</p>
        }
        
    </div>
  )
}

export default ProjectsList
