import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import personReducer from '../features/people/personSlice';
import classifierReducer from '../features/classifiers/classifierSlice';
import projectReducer from '../features/projects/projectSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    person: personReducer,
    classifier: classifierReducer,
    project: projectReducer,
  },
});
