import React, { useEffect, useRef } from 'react';
import { close } from '../../../features/classifiers/classifierSlice';
import { useDispatch } from 'react-redux';

function ValueDropdown({ showDropdown, categoryValues, handleValueSelect, setShowDropdown }) {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        dispatch(close());
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDropdown]);

  const handleSelect = (value) => {
    handleValueSelect(value);
    setShowDropdown(false);
    dispatch(close());
  };

  if (!showDropdown) {
    return null;
  }

  return (
    <div className="dropdown padding-5-top" ref={dropdownRef}>
      <ul>
        {categoryValues.map((value) => (
          <li key={value._id} onClick={() => handleSelect(value.name)}> 
            {value.name}
          </li>
        ))} 
      </ul>
    </div>
  );
}

export default ValueDropdown;