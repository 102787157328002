function Confirmation({message, setShow, action}) {

    const text = message || 'Vai esat pārliecināti?';

    const callAction = () => {
        action();
        setShow(false);
    }
        

  return (
    <div className='confirmation-window'>
      <div className="confirmation-box">
        <h5>{text}</h5>
        <div className="confirmation-buttons">
            <button onClick={callAction}>Jā</button>
            <button onClick={() => setShow(false)}>Nē</button>
        </div>
      </div>
    </div>
  )
}

export default Confirmation
