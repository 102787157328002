import { useState, useRef, useEffect } from 'react';
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 } from 'uuid';
import { FaTimes } from 'react-icons/fa';
import ProfileImageExample from '../ProfileImageExample';
import { toast } from 'react-toastify';
import Spinner from '../Spinner'; // Assuming you have a Spinner component

function ImageData({ setValue, thumbnail }) {
    const fileInput = useRef();
    const [fileName, setFileName] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [imageRef, setImageRef] = useState(null);
    const [loading, setLoading] = useState(false); // State to manage loading spinner

    const [overlayOpen, setOverlayOpen] = useState(false);

    useEffect(() => {
      setImageUrl(thumbnail.src);
    }, [thumbnail]);

    const closeOverlay = () => {
      setOverlayOpen(false);
      fileInput.current.click();
    };

    const createSquareImage = (imageFile) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
          const dimension = Math.min(image.width, image.height);
          const horizontalOffset = (image.width - dimension) / 2;
          const verticalOffset = (image.height - dimension) / 2;
          const canvas = document.createElement('canvas');
          canvas.width = dimension;
          canvas.height = dimension;
          const context = canvas.getContext('2d');
          context.drawImage(image, horizontalOffset, verticalOffset, dimension, dimension, 0, 0, dimension, dimension);
          canvas.toBlob(resolve, 'image/jpeg', 0.95);
        };
        image.onerror = reject;
        image.src = URL.createObjectURL(imageFile);
      });
    };

    const uploadImage = async (imageFile, imageName) => {
      const squareImage = await createSquareImage(imageFile);
      const newImageRef = ref(storage, `cb_images/${imageName}`);
      setImageRef(newImageRef);
      const uploadTask = uploadBytesResumable(newImageRef, squareImage);

      setLoading(true); // Start loading

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (progress === 100) {
            toast.success('Bilžu augšupielāde veiksmīga');
          }
        },
        (error) => {
          console.log(error);
          toast.error('Kaut kas nogāja greizi, lūdzu mēģini vēlreiz');
          setLoading(false); // Stop loading on error
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImageUrl(downloadURL);
            setLoading(false); // Stop loading on success
          });
        }
      );
    };

    const clearImage = () => {
      setImageUrl('');
      setImageRef(null);
      setFileName('');
    };

    const deleteImage = () => {
      if (imageRef) {
        deleteObject(imageRef)
          .then(() => {
            setValue('profile_image', 'placeholder.png', 'person');
            clearImage();
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (thumbnail) {
        setValue('profile_image', 'placeholder.png', 'person');
        clearImage();
      }
    };

    return (
      <div className="input-form-container padding-20-top">
        {overlayOpen && <ProfileImageExample closeOverlay={closeOverlay} />}

        <h2>Profila Bilde</h2>
        <div className='choose-thumbnail'>
          <div className="input-thumbnail-pic">
            {loading ? (
              <Spinner /> // Render spinner while uploading
            ) : imageUrl ? (
              <>
                <img src={imageUrl} alt="thumbnail" />
                <button className="input-delete-thumbnail" onClick={deleteImage}>
                  <FaTimes size={20} />
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="choose-thumbnail-controls">
            <button className='button-basic' onClick={() => setOverlayOpen(true)}>
              {(thumbnail || imageUrl) ? "Mainīt bildi" : "Pievienot bildi"}
            </button>

            <input
              type="file"
              ref={fileInput}
              style={{ display: 'none' }}
              onChange={(event) => {
                if (event.target.files[0]) {
                  const file = event.target.files[0];
                  const imageName = (file.name + v4()).toUpperCase();
                  setValue('profile_image', imageName, 'person');
                  setFileName(file.name);
                  uploadImage(file, imageName);
                } else {
                  setValue('profile_image', null, 'person');
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }

export default ImageData;
