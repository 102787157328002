function FilterTextInput({label, name, value, onChange}) {
  return (
    <div className="filter-input">
        <label>{label}</label>
        <input type="text" name={name} placeholder="..." value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  )
}

export default FilterTextInput
