import { Link } from 'react-router-dom';

function ProfileCard({ profile, selectPerson, selected, userType }) {

  return (
    <div className='profile-card'>
      { userType === "admin" && 
        <input 
          type="checkbox" 
          id={`checkbox-${profile.id}`} 
          checked={selected} 
          onChange={() => selectPerson(profile.id)} // Pass the profile id when the checkbox is changed
        />
      }
      

      <Link to={userType === "admin" ? `/pieteikums/${profile.id}` : `/people/${profile.id}`} key={profile.id}>
        {profile.thumbnail && profile.thumbnail.src && (
          <img 
            className={`main-${profile.main_status} inner-${profile.inner_status} outer-${profile.outer_status}`} 
            src={profile.thumbnail.src} 
            alt={`${profile.name}'s thumbnail`} // It's good practice to add an alt attribute
          />
        )}
        <div className="profile-card-title">
          <h5>{profile.name.split(' ')[0]}</h5>
          <p className="m">#{profile.id}</p>
        </div>
        <div className="card-data">
          <p className="m">Specialitate:</p>
          <p className="m">
            {profile.speciality === "talent" ? "Talants" : profile.speciality === "actor" ? "Aktieris" : "Pro"}
          </p>
        </div>
        <div className="card-data">
          <p className="m">Garums:</p>
          <p className="m">{profile.height}</p>
        </div>
        <div className="card-data">
          <p className="m">Vecums:</p>
          <p className="m">{new Date().getFullYear() - new Date(profile.birthdate).getFullYear()}</p>
        </div>
      </Link>
    </div>
  );
}

export default ProfileCard;
