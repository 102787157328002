import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MdDeleteOutline } from "react-icons/md";

const TagSelector = ({ selectedValues, setSelectedValues }) => {
    const { all_values } = useSelector((state) => state.person);

    const [inputValue, setInputValue] = useState('');

    // Filter options based on input value
    const filteredOptions = all_values.filter(option =>
        option.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleOptionClick = (option) => {
        if (!selectedValues.includes(option.value_id)) {
            setSelectedValues([...selectedValues, option.value_id]);
        }
        setInputValue(''); // Clear the input field after selecting an option
    };

    const removeTag = (value_id) => {
        setSelectedValues(selectedValues.filter(id => id !== value_id));
    };

    return (
        <div>
            <div className="tag-input-container">
                {selectedValues.map(value_id => {
                    const option = all_values.find(opt => opt.value_id === value_id);
                    return (
                        <div className="tag" key={value_id}>
                            {option?.name}
                            <span className="tag-close" onClick={() => removeTag(value_id)}>
                                <MdDeleteOutline className="delete-icon-tags" size={15} style={{ marginBottom: "-3px" }} />
                            </span>
                        </div>
                    );
                })}
                <input
                    type="text"
                    className='basic-input'
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Meklēt..."
                />
            </div>
            {inputValue && (
                <ul className="options-list">
                    {filteredOptions.map(option => (
                        <li key={option.value_id} onClick={() => handleOptionClick(option)}>
                            {option.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default TagSelector;
