import { useState, useEffect } from 'react';
import { MdOutlineAttachEmail } from "react-icons/md";
import { deletePeople, sendPics } from "../../features/people/personSlice"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function CastingDati({person, setValue}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [arch, setArch] = useState(false);
    const [hasPaid, setHasPaid] = useState(false);
    const [held, setHeld] = useState(false);
    const [free, setFree] = useState(false);

    useEffect(() => {
        setArch(person.archived);
        setHasPaid(person.paid_for === 1);
        setHeld(person.paid_for === 2);
        setFree(person.paid_for === 3);
    }, [])


    const handleArch = (e) => {
        setArch(e.target.checked)
        setValue('archived', e.target.checked, 'person');
    }

    const handlePaid = (e) => {

        // check name of input
        if(e.target.checked){
            if(e.target.name === 'paid'){
                setHasPaid(e.target.checked)
                setFree(false)
                setHeld(false)
                setValue('paid_for', 1, 'person');
            } else if(e.target.name === 'held'){
                setHeld(e.target.checked)
                setHasPaid(false)
                setFree(false)
                setValue('paid_for', 2, 'person');
            } else if(e.target.name === 'free'){
                setFree(e.target.checked)
                setHasPaid(false)
                setHeld(false)
                setValue('paid_for', 3, 'person');
            }
        } else {
            setHasPaid(e.target.checked)
            setFree(e.target.checked)
            setHeld(e.target.checked)
            setValue('paid_for', 0, 'person');
        }
    
    }

    const handleDelete = () => {
        if(window.confirm('Vai tiešām vēlaties dzēst šo profilu?')){
            dispatch(deletePeople([person.id]))
            navigate('/profiles')
        }
    }

    if(!person) return null;

  return (
    <div>
      <div className="input-form-container padding-20-bottom">
        <h2 className='padding-10-bottom'>Datubāzes statuss:</h2>

        <div className="info-box">
            <ul>
                <li>
                    <input type="checkbox" name="active" checked={arch} onChange={(e) => handleArch(e)}/>
                    <span className='padding-10-left'><b>Arhivēts</b></span>
                </li>
                <li>
                    <input type="checkbox" name="paid" checked={hasPaid} onChange={(e) => handlePaid(e)}/>
                    <span className='padding-10-left'><b>Samaksāts</b></span>
                </li>
                <li>
                    <input type="checkbox" name="held" checked={held} onChange={(e) => handlePaid(e)}/>
                    <span className='padding-10-left'><b>Ieturēts</b></span>
                </li>
                <li>
                    <input type="checkbox" name="free" checked={free} onChange={(e) => handlePaid(e)}/>
                    <span className='padding-10-left'><b>Attaisnots</b></span>
                </li>
                <li>
                    <span className='padding-10-right'><b>Pievienots datubāzei:</b></span><span>{new Date(person.created).toLocaleDateString('en-GB')}</span>
                </li>
                <li>
                    <span className='padding-10-right'><b>Atjaunots:</b></span><span>{new Date(person.updated).toLocaleDateString('en-GB')}</span>
                </li>

                {
                    person.images_updated ? 
                    <li>
                        <span className='padding-10-right'><b>Bildes atjaunotas:</b></span>
                        <span>{new Date(person.images_updated_date).toLocaleDateString('en-GB')}</span>
                    </li>
                    :
                    null
                }

                <li>
                    <span className='padding-10-right'><b>Bildes nosūtītas:</b></span>
                    <span>
                        {person.images_sent === 1 ? new Date(person.images_sent_date).toLocaleDateString('en-GB') : 'Nē'}
                    </span>
                </li>
                
                <li>

                </li>
            </ul>
        </div>
        <div className="flex gap-10 padding-10-top padding-10-bottom">
            <button onClick={() => dispatch(sendPics(person.id))} className='submit-button' type="button"><span className="padding-10-right"><MdOutlineAttachEmail size={15} style={{marginBottom: "-3px"}}/></span>Nosūtīt bildes</button>
            <button className="button-basic" onClick={handleDelete}>Dzēst profilu</button>
        </div>
      </div>
    </div>
  )
}

export default CastingDati