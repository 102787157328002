import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePeople } from '../../features/people/personSlice';
import ProfileCard from '../ProfileCard';
import Spinner from '../Spinner';

function ProfilesGrid({ people, userType, unverified = false }) {

  const { isLoading } = useSelector((state) => state.person);

  const dispatch = useDispatch();

  const [selected, setSelected] = useState(() => {
    const key = unverified ? 'selectedPeopleUnverified' : 'selectedPeople';
    const storedSelected = localStorage.getItem(key);
    return storedSelected ? JSON.parse(storedSelected) : [];
  });

  const handleClick = (id) => {
    const updatedSelected = selected.includes(id)
      ? selected.filter(person_id => person_id !== id)
      : [...selected, id];
    
    setSelected(updatedSelected);
    localStorage.setItem(unverified ? 'selectedPeopleUnverified' : 'selectedPeople', JSON.stringify(updatedSelected));
  };

  const deleteProfile = () => {
    const selectedPeople = people.filter(person => selected.includes(person.id));
    if (selectedPeople.length === 0) {
      alert('Izvēlieties cilvēkus, ko dzēst');
      return;
    }
    const ids = selectedPeople.map(person => person.id);
    dispatch(deletePeople(ids));
  }

  if(isLoading) return <Spinner />

  if(people.length === 0) return <p>Pieteikumu pagaidām nav...</p>

  return (
    <div className="profiles">
        {unverified && (
            <div className='padding-20-bottom'>
                <button className="button-basic" onClick={deleteProfile}>Dzēst Izvēlētos</button>
            </div>
        )}

        <div className='profile-grid'>
            {people.map((profile) => (
                <ProfileCard 
                    key={profile.id} 
                    profile={profile} 
                    selectPerson={() => handleClick(profile.id)} 
                    selected={selected.includes(profile.id)} 
                    userType={userType}
                />
            ))}
        </div>
    </div>
  );
}

export default ProfilesGrid;
