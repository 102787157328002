import axios from 'axios'

const API_URL = '/people'
const EMAIL_URL = '/emails/'

// Get single person private
const getPerson = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const url = API_URL + '/'+ id
  const response = await axios.get(url, config)
  return response.data
}

// Get single person private
const getPersonPublic = async (id) => {
  const url = API_URL + '/public/'+ id
  const response = await axios.get(url)
  return response.data
}

// Get single person private
const addPerson = async (data) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // }

  const url = API_URL + '/'
  const response = await axios.post(url, data)
  return response.data
}

// Get single person private
const editPerson = async (data) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // }

  const url = API_URL + '/'
  const response = await axios.put(url, data)
  return response.data
}

const deletePeople = async (token, ids) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const url = API_URL + '/'+ ids
  const response = await axios.delete(url, config)
  return response.data
}

// Get single person private
const sendPics = async (id) => {
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // }

  const url = EMAIL_URL + id
  const response = await axios.post(url)
  return response.data
}

const getPeople = async (token, page, filters) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

console.log(filters);

  // Convert filters object to query parameters
  const queryParams = Object.keys(filters)
    .map((key) => {
      const value = filters[key];
      // Ensure values are properly URL-encoded and not null or undefined
      return value !== null && value !== undefined && value !== "" 
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        : '';
    })
    .filter(Boolean)
    .join('&');

  const url = `${API_URL}?page=${page}${queryParams ? `&${queryParams}` : ''}`;
  console.log(url);

  const response = await axios.get(url, config);
  return response.data;
};

const getPeoplePublic = async (page, filters) => {
  // Convert filters object to query parameters
  const queryParams = Object.keys(filters)
    .map((key) => {
      const value = filters[key];
      return value !== null && value !== undefined && value !== "" 
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        : '';
    })
    .filter(Boolean)
    .join('&');

  const url = `${API_URL}/public?page=${page}${queryParams ? `&${queryParams}` : ''}`;
  console.log(url);

  const response = await axios.get(url);
  return response.data;
};



// Get people with filters (private)
const getUnverifiedPeople = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const url = API_URL + '/unverified';

  const response = await axios.get(url, config);
  return response.data;
};

const personService = {
    getPeople,
    getPerson,
    addPerson,
    getPersonPublic,
    getUnverifiedPeople,
    editPerson,
    sendPics,
    deletePeople,
    getPeoplePublic
}

export default personService