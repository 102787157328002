import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValues } from '../../../features/classifiers/classifierSlice';
import ValueDropdown from './ValueDropdown';

// function InputSelection({ name, placeholder, setValue, editValue, voiceInput, voiceValue, projectInput, projectValue }) {
  // editValue, voiceInput, voiceValue, projectInput, projectValue 
function InputSelection({ name, placeholder, setValue, editValue }) {

  // console.log(editValue);
  
  const dispatch = useDispatch();
  const { values } = useSelector((state) => state.classifier);

  const [categoryValues, setCategoryValues] = useState([]);
  const [originalCategoryValues, setOriginalCategoryValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const triggerClassifier = (class_category) => {
    dispatch(getValues(class_category));
    setShowDropdown(true);
  };

  useEffect(() => {
    setCategoryValues(values || []);
    setOriginalCategoryValues(values || []);
  }, [values]);

  useEffect(() => {
    if (editValue) {
      setSelectedValue(editValue.values);
    }
  }, [editValue]);

  const handleValueChange = (e) => {
    const inputValue = createTag(e.target.value);
    setSelectedValue(e.target.value);
  
    setValue({
      category: name,
      tags: inputValue,
      values: e.target.value
    });
  
    // Filter the options based on user input and exclude perfect matches
    const filteredOptions = originalCategoryValues.filter((value) =>
      value.tag.slice(0, inputValue.length) === inputValue && value.tag !== inputValue
    );
  
    setCategoryValues(filteredOptions);
  };

  const handleValueSelect = (value) => {
    const tag = createTag(value);
    setSelectedValue(value);
    setValue({
      category: name,
      tags: tag,
      values: value
    });
    setShowDropdown(false);
  };

  function createTag(name) {
    let lowercaseName = name.toLowerCase();
    let normalized_name = lowercaseName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let tag = normalized_name.replace(/[^a-z0-9]+/g, "-");
    return tag;
  }

  return (
    <div className="input-selection">
      <div className="dropdown-container">
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          onClick={() => triggerClassifier(name)}
          // onFocus={() => triggerClassifier(name)} 
          // onBlur={() => setShowDropdown(false)} 
          value={selectedValue}
          onChange={handleValueChange}
        />
        <ValueDropdown showDropdown={showDropdown} categoryValues={categoryValues} handleValueSelect={handleValueSelect} setShowDropdown={setShowDropdown} />
      </div>
    </div>
    
  );
}

export default InputSelection;