import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout, reset } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    dispatch(reset());
    navigate('/');
  }, [dispatch, navigate]);

  return null;
}

export default Logout;