import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../features/classifiers/classifierSlice';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { Link } from 'react-router-dom';

function ClassifierCategories() {
    const dispatch = useDispatch();

    const { categories, isLoading, isError, message } = useSelector((state) => state.classifier);

    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        toast.error(message);
    }

    return (
        <div className='classifier-edit-list'>
            <ul className='category-list'>
                {categories.map((category) => (
                    <li key={category._id}>
                        <Link to={`/classifiers/${category}`} className='category-link'>
                            {category}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ClassifierCategories;