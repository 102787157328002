import { useState, useEffect } from 'react';
import InputTagSelection from './input_helpers/InputTagSelection';
import { FaArrowTurnUp } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";

function AktiermakslasDati({ setValue, person, features, transformFeature }) {

  const [specialityHelper, setSpecialityHelper] = useState(false);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if(person){
      setSelected(person.speciality);
    }
  }, [person]);

  // useEffect(() => {
  //   if(features){
  //     setValue('subspeciality', transformFeature(features.subspeciality) || {}, 'features');
  //   }
  // }, [features, setValue, transformFeature]);

  const changeSpeciality = (speciality) => {
    setSelected(speciality);
    setValue('speciality', speciality, 'person');
  }

  const handleExperienceChange = (event) => {
    setValue('experience', event.target.value, 'person');
  }

  return (
    <div>
      <div className="input-form-container padding-20-top">
        <h2>Aktiermākslas informācija</h2>
        <form className="input-form padding-10-top">
          {specialityHelper &&
            <div className="input-overlay-images">
              <button className="close-button" onClick={(e) => {
                e.preventDefault();
                setSpecialityHelper(false);
              }}>
                <FaTimes />
              </button>
              <p className='width-50'>
                Kas es esmu?
                <br /><br />
                <li>
                  Ja esi profesionāli apguvis aktiermākslu un/vai tev ir iegūta augstākā izglītība
                  dramatiskā teātra vai kino aktiera mākslā, tu esi AKTIERIS
                </li>
                <li>
                  Ja esi profesionāli apguvis vai jau profesionāli darbojies kādā citā jomā (deja,
                  mūzika, sports, audiovizuālā māksla, utt) tu esi PROFESIONĀLIS un vienu
                  lauciņu zemāk tev obligāti jānorāda sava specializācija
                </li> 
                <li>
                  Ja esi amatieru līmenī darbojies kino vai teātrī, izmēģinājis sevi dažādās dzīves
                  jomā,s vai arī vienkārši mīli kino un vienmēr esi vēlējies piedalīties kādā filmā,
                  tad tu esi TALANTS, un mēs noteikti gribam tev dot iespēju piepildīt savu
                  sapni!
                </li>
              </p>
            </div>
          }
          <div className="form-input">
            <label>Specialitāte*</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input 
                type="radio" 
                id="actor" 
                name="speciality" 
                value="actor" 
                checked={selected === 'actor'} 
                onChange={() => changeSpeciality('actor')} 
              />
              <label htmlFor="actor" style={{ marginRight: '10px' }}>Aktieris</label>
              <input 
                type="radio" 
                id="professional" 
                name="speciality" 
                value="professional" 
                checked={selected === 'professional'} 
                onChange={() => changeSpeciality('professional')} 
              />
              <label htmlFor="professional" style={{ marginRight: '10px' }}>Profesionālis</label>
              <input 
                type="radio" 
                id="talent" 
                name="speciality" 
                value="talent" 
                checked={selected === 'talent'} 
                onChange={() => changeSpeciality('talent')} 
              />
              <label htmlFor="talent">Talants</label>
            </div>
            <div className="flex">
              <button className="size-helper button-basic" onClick={(e) => {
                e.preventDefault();
                setSpecialityHelper(true);
              }}>
                Specialitātes paskaidrojums <FaArrowTurnUp />
              </button>
            </div>
          </div>
          
          {(selected === 'professional' || selected === 'actor') &&
            <div className="form-input">
              <label>Specializācija</label>
              <InputTagSelection name="subspeciality" placeholder="Specializācija" setValues={(values) => setValue('subspeciality', values, 'features')} editValues={features ? features.subspeciality : null} />
            </div>
          }
          
          <div className="form-input">
            <label>
              Video saite 
              -Iepazīšanās video, showreel, improvizācija vai kāds video, kas ataino tavas
              aktiermākslas vai citas prasmes</label>
            <input defaultValue={person ? person.video : ''} type="text" name="video" placeholder="Video saite" onChange={(e) => setValue('video', e.target.value, 'person')} />
          </div>

          <div className="form-input">
            <label htmlFor="experience">Filmēšanās pieredze:</label>
            <textarea defaultValue={person ? person.experience : ''} name='experience' placeholder='Konkrēti piemēri un lomas (filmas, reklāmas, TV, u.c)' onChange={handleExperienceChange}></textarea>
          </div>
          
        </form>
      </div>
    </div>
  );
}

export default AktiermakslasDati;
