import { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import InputSelection from './input_helpers/InputSelection';
import man_eur from "../../images/man_eur.png";
import woman_eur from "../../images/woman_eur.png";
import InputTagSelection from './input_helpers/InputTagSelection';
import { FaArrowTurnUp } from "react-icons/fa6";

function IpasibasDati({ setValue, person, features, transformFeature }) {
  
  // useEffect(() => {
  //   if(person){
  //     setValue('height', person.height || '', 'person');
  //     setValue('cloth_size', person.cloth_size || '', 'person');
  //     setValue('foot_size', person.foot_size || '', 'person');
  //     setValue('voice', person.voice || '', 'person');
  //     setValue('weight', person.weight || '', 'person');
  //   }
  // }, [person, setValue]);

  // useEffect(() => {
  //   if(features){
  //     setValue('haircolor', transformFeature(features.haircolor) || {}, 'features');
  //     setValue('eyecolor', transformFeature(features.eyecolor) || {}, 'features');
  //     setValue('nationality', transformFeature(features.nationality) || {}, 'features');
  //     setValue('piercing', transformFeature(features.piercing) || [], 'features');
  //   }
  // }, [features, setValue, transformFeature]);

  const [openSizehelper, setOpenSizehelper] = useState(false);

  // console.log(features)

  const handleSizeHelper = () => {
    setOpenSizehelper(!openSizehelper);
  }

  return (
    <div>
      <div className="input-form-container padding-20-top">
        <h2>Personīgās īpašības</h2>
        <form className="input-form padding-10-top">
          {openSizehelper &&
            <div className="input-overlay-images">
              <button className="close-button" onClick={handleSizeHelper}>
                <FaTimes />
              </button>
              <img src={man_eur} alt="" />
              <img src={woman_eur} alt="" />
            </div>
          }
          <div className="form-input">
            <label>Garums(cm)*</label>
            <input defaultValue={person ? person.height : ''} type="number" name="height" placeholder="Garums (cm)" onChange={e => setValue('height', e.target.value, 'person')} />
          </div>
          <div className="form-input">
            <label>Svars(kg)</label>
            <input defaultValue={person ? person.weight : ''} type="number" name="weight" placeholder="Svars (kg)" onChange={e => setValue('weight', e.target.value, 'person')} />
          </div>
          <div className="form-input">
            <label>Apģērba izmērs (EU)</label>
            <input defaultValue={person ? person.cloth_size : ''} type="number" name="clothesSize" placeholder="Apģērba izmērs (EU)" onChange={e => setValue('cloth_size', e.target.value, 'person')} />
            <div className="flex padding-5-top">
              <button className="size-helper button-basic" onClick={(e) => {
                e.preventDefault();
                handleSizeHelper();
              }}>
                Apskatīt izmēru tabulu <FaArrowTurnUp />
              </button>
            </div>
          </div>
          <div className="form-input">
            <label>Apavu izmērs (EU)</label>
            <input defaultValue={person ? person.foot_size : ''} type="number" name="footSize" placeholder="Apavu izmērs (EU)" onChange={e => setValue('foot_size', e.target.value, 'person')} />
          </div>
          <div className="form-input">
            <label>Balss tembrs</label>
            <InputSelection name="voice" placeholder="Balss tembrs" setValue={(value) => setValue('voice', value.values, 'person')} editValue={person ? {values: person.voice} : null} />
          </div>
          <div className="form-input">
            <label>Matu krāsa</label>
            <InputSelection name="haircolor" placeholder="Matu krāsa" setValue={(value) => setValue('haircolor', value, 'features')} editValue={features ? features.haircolor : null} />
          </div>
          <div className="form-input">
            <label>Pīrsingi</label>
            <InputTagSelection name="piercing" placeholder="Pīrsingi" setValues={(values) => setValue('piercing', values, 'features')} editValues={features ? features.piercing : null} />
          </div>
          <div className="form-input">
            <label>Acu krāsa</label>
            <InputSelection name="eyecolor" placeholder="Acu krāsa" setValue={(value) => setValue('eyecolor', value, 'features')} editValue={features ? features.eyecolor : null} />
          </div>
          <div className="form-input">
            <label>Nacionalitāte</label>
            <InputSelection name="nationality" placeholder="Nacionalitāte" setValue={(value) => setValue('nationality', value, 'features')} editValue={features ? features.nationality : null} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default IpasibasDati;
