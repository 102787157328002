import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteValue, getValues } from '../../features/classifiers/classifierSlice';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { useParams } from 'react-router-dom';

function EditClassifier() {
    const dispatch = useDispatch();
    const { values, isLoading, isError, message } = useSelector((state) => state.classifier);
    const { category } = useParams();

    useEffect(() => {
        console.log(category);
        dispatch(getValues(category));
    }, [category, dispatch]);

    if (isLoading) {
        return <Spinner />;
    }

    if (isError) {
        toast.error(message);
    }

    const handleDelete = (value_id, value_name) => {
        if (window.confirm('Vai tiešām vēlaties dzēst vērtību: ' + value_name + value_id + '?')) {
            // Dispatch delete action here
            dispatch(deleteValue({ category, value: value_id }));
        }
    };

    if(!values){
        return <Spinner />;
    }

    return (
        <div className='classifier-edit-list'>
            <ul className='category-list'>
                {values.map((value) => (
                    <li key={value.value_id} className='value-item'>
                        <span>{value.name}</span>
                        <button className='button-basic' onClick={() => handleDelete(value.value_id, value.name)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default EditClassifier;