import { useEffect, useState } from 'react';

function PersonasDati({ setValue, person }) {
  const [manSelected, setManSelected] = useState(true);
  const [dobValue, setDobValue] = useState('');

  useEffect(() => {
    if (person) {
      setDobValue(person.birthdate ? new Date(person.birthdate).toISOString().split('T')[0] : '');
      setManSelected(person.species === 'man');
    }
  }, [person]);

  const changeGender = (button) => {
    const gender = button === 'man' ? 'man' : 'woman';
    setManSelected(button === 'man');
    setValue('species', gender, 'person');
  }

  const handleDateChange = (e) => {
    setDobValue(e.target.value);
    setValue('birthdate', e.target.value, 'person');
  };

  return (
    <div>
      <div className="input-form-container">
        <h2>Personas Dati</h2>
        <form className='input-form padding-10-top'>
          <div className="form-input no-padding">
            <label>Dzimums*</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="radio"
                id="male"
                name="gender"
                value="man"
                checked={manSelected}
                onChange={() => changeGender('man')}
              />
              <label htmlFor="male" style={{ marginRight: '10px' }}>Vīrietis</label>
              <input
                type="radio"
                id="female"
                name="gender"
                value="woman"
                checked={!manSelected}
                onChange={() => changeGender('woman')}
              />
              <label htmlFor="female">Sieviete</label>
            </div>
          </div>

          <div className="form-input">
            <label htmlFor="firstName">Vārds*</label>
            <input
              defaultValue={person ? person.name : ''}
              type="text"
              name="firstName"
              placeholder="Vārds"
              required
              onChange={(e) => setValue('name', e.target.value, 'person')}
            />
          </div>

          <div className="form-input">
            <label htmlFor="lastName">Uzvārds*</label>
            <input
              defaultValue={person ? person.surname : ''}
              type="text"
              name="lastName"
              placeholder="Uzvārds"
              required
              onChange={(e) => setValue('surname', e.target.value, 'person')}
            />
          </div>

          <div className="form-input">
            <label htmlFor="stageName">Skatuves vārds</label>
            <input
              defaultValue={person ? person.nickname : ''}
              type="text"
              name="stageName"
              placeholder="Skatuves vārds"
              onChange={(e) => setValue('nickname', e.target.value, 'person')}
            />
          </div>

          <div className="form-input">
            <label htmlFor="dob">Dzimšanas datums*</label>
            <input
              required
              type="date"
              name="dob"
              placeholder="Dzimšanas datums"
              value={dobValue}
              onChange={handleDateChange}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default PersonasDati;