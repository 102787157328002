import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { editPerson, getSinglePerson, reset } from '../../features/people/personSlice';
import InputForm from '../../components/InputForm';
import Spinner from '../../components/Spinner';

function transformFeature(features = []) {
  if (features.length === 0) return '';
  const { category } = features[0];
  const values = features.map(({ values }) => values).join(', ');
  const tags = features.map(({ tags }) => tags).join(', ');
  return { category, values, tags };
}

function EditPerson() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileId } = useParams();
  const { person, isLoading } = useSelector((state) => state.person);
  const admin = useSelector((state) => !!state.auth.user);

  const [personObject, setPersonObject] = useState(null);
  const [featuresObject, setFeaturesObject] = useState(null);

  useEffect(() => {
    if (profileId) {
      dispatch(getSinglePerson(profileId));
    }
  }, [profileId, dispatch]);

  useEffect(() => {
    if (person?.person) {
      const { person: p, images = [], features = {} } = person;
      setPersonObject({
        ...p,
        profile_image: person.thumbnail || '',
        images,
      });

      setFeaturesObject({
        degree: transformFeature(features.degree),
        danceskill: transformFeature(features.danceskill),
        singskill: transformFeature(features.singskill),
        musicskill: transformFeature(features.musicskill),
        sportskill: transformFeature(features.sportskill),
        swimskill: transformFeature(features.swimskill),
        driveskill: transformFeature(features.driveskill),
        languageskill: transformFeature(features.languageskill),
        otherskill: transformFeature(features.otherskill),
        educational_institution: transformFeature(features.educational_institution),
        learned_profession: transformFeature(features.learned_profession),
        subspeciality: transformFeature(features.subspeciality),
        eyecolor: transformFeature(features.eyecolor),
        workplaces: transformFeature(features.workplaces),
        want_participate: transformFeature(features.want_participate),
        dont_want_participate: transformFeature(features.dont_want_participate),
        interested_in: transformFeature(features.interested_in),
        haircolor: transformFeature(features.haircolor),
        nationality: transformFeature(features.nationality),
        piercing: transformFeature(features.piercing),
        city: transformFeature(features.city),
      });
    }

    return () => {
      dispatch(reset());
    };
  }, [person]);

  const handleSubmit = () => {
    dispatch(editPerson({ person: personObject, features: featuresObject }));
    navigate("/profiles");
  };

  if(isLoading){
    return <Spinner />
  }

  return (
    <div>
      {personObject && featuresObject && (
        <InputForm
          personObject={personObject}
          setPersonObject={setPersonObject}
          featuresObject={featuresObject}
          setFeatures={setFeaturesObject}
          submit={handleSubmit}
          admin={admin}
        />
      )}
    </div>
  );
}

export default EditPerson;
