import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { editProject, reset } from '../features/projects/projectSlice';

function EditProjectForm({titleValue, peopleValues, projectId, setEditInfo, editInfo}) {

    const [title, setTitle] = useState(titleValue);

    const [showAge, setShowAge] = useState(peopleValues ? peopleValues.showAge : false);
    const [showHeight, setShowHeight] = useState(peopleValues ? peopleValues.showHeight : false);
    const [showShoeSize, setShowShoeSize] = useState(peopleValues ? peopleValues.showShoeSize : false);
    const [showClothingSize, setShowClothingSize] = useState(peopleValues ? peopleValues.showClothingSize : false);
    const [showPhone, setShowPhone] = useState(peopleValues ? peopleValues.showPhone : false);
    const [showLanguages, setShowLanguages] = useState(peopleValues ? peopleValues.showLanguages : false);

    const dispatch = useDispatch();
    const handleSubmit = () => {
        const project = {
            title: title,
            people_values: {
                showAge: showAge,
                showHeight: showHeight,
                showShoeSize: showShoeSize,
                showClothingSize: showClothingSize,
                showPhone: showPhone,
                showLanguages: showLanguages,
            }
        }
        dispatch(editProject({projectId: projectId, data: project}))
        setEditInfo(false)
    }

  return (
    <div className='input-form'>
      <form className='input-form padding-20-top'>
          <div className="form-input">
            <label>Projekta nosaukums</label>
            <input type="text" name="name" value={title} onChange={(e) => setTitle(e.target.value)}/>
          </div>
          <div className="info-box">
            <label>Informācija ko uzrādīt par talantu:</label>
            <div className="padding-10-top"></div>
            <ul>
              <li>
                <input type="checkbox" name="showHeight" checked={showHeight} onChange={(e) => setShowHeight(e.target.checked)}/>
                <span className='padding-10-left'><b>Augums</b></span>
              </li>
              <li>
                <input type="checkbox" name="showShoeSize" checked={showShoeSize} onChange={(e) => setShowShoeSize(e.target.checked)}/>
                <span className='padding-10-left'><b>Apavu izmērs</b></span>
              </li>
              <li>
                <input type="checkbox" name="showClothingSize" checked={showClothingSize} onChange={(e) => setShowClothingSize(e.target.checked)}/>
                <span className='padding-10-left'><b>Apģērba izmērs</b></span>
              </li>
              <li>
                <input type="checkbox" name="showPhone" checked={showPhone} onChange={(e) => setShowPhone(e.target.checked)}/>
                <span className='padding-10-left'><b>Telefons</b></span>
              </li>
              <li>
                <input type="checkbox" name="showLanguages" checked={showLanguages} onChange={(e) => setShowLanguages(e.target.checked)}/>
                <span className='padding-10-left'><b>Valodas</b></span>
              </li>
              <li>
                <input type="checkbox" name="showAge" checked={showAge} onChange={(e) => setShowAge(e.target.checked)}/>
                <span className='padding-10-left'><b>Vecums</b></span>
              </li>
            </ul>
          </div>

      </form>
      <div className="flex padding-10-top gap-10">
        <button className="button-basic" onClick={handleSubmit}>Saglabāt</button>
        <button className="button-basic" onClick={() => setEditInfo(!editInfo)}>{!editInfo ? "Rediģēt info" : "Atcelt"}</button>
      </div>
      
    </div>
  )
}

export default EditProjectForm