// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBE2zDOlTid6SMvcw4ALO2qK7NPKMkRUUQ",
  authDomain: "castingbridge-4cd49.firebaseapp.com",
  projectId: "castingbridge-4cd49",
  storageBucket: "castingbridge-4cd49.appspot.com",
  messagingSenderId: "828207437935",
  appId: "1:828207437935:web:2875b3fba83fc6383dd59a",
  measurementId: "G-YD48E5GJ96"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);