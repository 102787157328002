import { FaTimes } from "react-icons/fa";

function SelectedPeopleCard({profile, removeSelection}) {
  return (

    <div className='project-profile-card'>
          <FaTimes 
              className="delete-icon" 
              style={{position: "absolute", top: "10px", right: "10px", cursor: "pointer"}} 
              onClick={() => removeSelection(profile.person_data.id)}
          />
          {profile.thumbnail ? <img src={profile.thumbnail.src}/> : "Nothing"}
          <div className="project-profile-card-title">
            <h5 className="padding-20-top">{profile.person_data.name.split(' ')[0]}</h5>
          </div>
        </div>

  )
}

export default SelectedPeopleCard