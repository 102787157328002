import ProjectProfileCard from './ProjectProfileCard';
import SelectedPeopleCard from './SelectedPeopleCard';

function SelectedPeopleGrid({people, removeSelection}) {
  return (
    <div className='project-profiles'>
            {people.map((profile) => (
                <SelectedPeopleCard key={profile.person_data.id} profile={profile} removeSelection={removeSelection}/>
            ))}
    </div>
  )
}

export default SelectedPeopleGrid
