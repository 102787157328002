import axios from 'axios'

const API_URL = '/projects/'

// Get projects
const getProjects = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL, config)
  return response.data
}

// Get project
const getProject = async (id) => {
  const response = await axios.get(API_URL + id)
  return response.data
}

// Update color
const updateColor = async (id, color, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  console.log(API_URL + id, {color})
  const response = await axios.put(API_URL + id, {color}, config)
  return response.data
}

// Add person comment
const addPersonComment = async (id, person_id, comment) => {
  const response = await axios.post(API_URL + "comment-update/" + id, {person_id, comment})
  return response.data
}

// Add person comment
const updateLiked = async (id, person_id) => {
  const response = await axios.post(API_URL + "update-liked/" + id, {person_id})
  return response.data
}

// Create new project
const addProject = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, data, config)
  return response.data
}

const getSelectedPeople = async (people, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const url = '/people/' + 'selected-people/'+ people
  console.log(url)
  const response = await axios.get(url, config)
  return response.data
}

const removeFromProject = async (projectId, personId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { person_id: personId } // Moved data to config
  }
  const response = await axios.delete(`/projects/people/${projectId}`, config);
  return response.data
}

const addToProject = async (projectId, people, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.put(`/projects/add-people/${projectId}`, { people }, config);
  return response.data;
}

const deleteProject = async (projectId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.delete(`/projects/${projectId}`, config);
  return response.data;
}
// Service function
const editProjectService = async (projectId, data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  console.log(data)
  const response = await axios.put(`/projects/edit/${projectId}`, data, config);
  return response.data;
}

const getSpreadsheet = async (projectId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(`/projects/${projectId}/export`, {
    responseType: 'arraybuffer', // Ensure response type is arraybuffer
  });
  return response.data;
}

const getProjectPerson = async (projectId, personId) => {
  console.log(projectId, personId)
  const response = await axios.get(`/projects/${projectId}/${personId}`)
  return response.data
}

const projectService = {
  getProjects,
  addProject,
  getProject,
  updateColor,
  getSelectedPeople,
  removeFromProject,
  addToProject,
  deleteProject,
  editProjectService,
  addPersonComment,
  updateLiked,
  getSpreadsheet,
  getProjectPerson
}

export default projectService