import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputForm from '../../components/InputForm';
import { addPerson } from '../../features/people/personSlice';
import { useDispatch, useSelector } from 'react-redux';

const defaultPersonObject = {
  id: '',
  created: new Date(),
  updated: new Date(),
  images_updated: false,
  images_updated_date: new Date(),
  paid_for: '',
  images_sent: '',
  images_sent_date: '',
  name: '',
  surname: '',
  nickname: '',
  birthdate: '',
  my_phone_code: '',
  my_phone: '',
  email: '',
  home_address: '',
  height: '',
  weight: '',
  foot_size: '',
  cloth_size: '',
  voice: '',
  profile_image: '',
  species: '',
  parent_name: '',
  parent_phone_code: '',
  parent_phone: '',
  speciality: '',
  experience: '',
  video: '',
  current_occupation: '',
  workplace: '',
  images: [],
};

const defaultFeaturesObject = {
  degree: '',
  danceskill: '',
  singskill: '',
  musicskill: '',
  sportskill: '',
  swimskill: '',
  driveskill: '',
  languageskill: '',
  otherskill: '',
  educational_institution: '',
  learned_profession: '',
  subspeciality: '',
  eyecolor: '',
  workplaces: '',
  want_participate: '',
  dont_want_participate: '',
  interested_in: '',
  haircolor: '',
  nationality: '',
  piercing: '',
  city: '',
};

function AddPerson() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const admin = useSelector((state) => !!state.auth.user);

  const [personObject, setPersonObject] = useState(defaultPersonObject);
  const [featuresObject, setFeaturesObject] = useState(defaultFeaturesObject);

  const handleSubmit = () => {
    dispatch(addPerson({ person: personObject, features: featuresObject, admin }));
    navigate("/");
  };

  return (
    <div>
      <InputForm
        personObject={personObject}
        setPersonObject={setPersonObject}
        featuresObject={featuresObject}
        setFeatures={setFeaturesObject}
        submit={handleSubmit}
      />
    </div>
  );
}

export default AddPerson;
