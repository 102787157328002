import { useState } from 'react';
import { Gallery } from 'react-grid-gallery';

import PersonasDati from './inputs/PersonasDati';
import GimenesDati from './inputs/GimenesDati';
import KontaktuDati from './inputs/KontaktuDati';
import IpasibasDati from './inputs/IpasibasDati';
import AktiermakslasDati from './inputs/AktiermakslasDati';
import PrasmesDati from './inputs/PrasmesDati';
import DarbsIzglitibaDati from './inputs/DarbsIzglitibaDati';
import VelmesDati from './inputs/VelmesDati';
import ImageData from './inputs/ImageDate';
import MultipleImages from './inputs/MultipleImages';
import CastingDati from './inputs/CastingDati';
import ImageOverlay from './ImageOverlay';

function InputForm({submit, personObject, featuresObject, setPersonObject, setFeatures, admin}) {

  const transformFeature = (features) => {
    if(!features) return;
    const category = features[0].category;
    const values = features.map(feature => feature.values).join(', ');
    const tags = features.map(feature => feature.tags).join(', ');

    return {
        category: category,
        values: values,
        tags: tags
    };
  };

  const handleChange = (field, value, category) => {
    if (category === 'person') {
      setPersonObject((prev) => ({ ...prev, [field]: value }));
    } else if (category === 'features') {
      setFeatures((prev) => ({ ...prev, [field]: value }));
    }
  };

  const [editImages, setEditImages] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleSelect = (index) => {
    const updatedImages = personObject.images.map((image, i) => {
      if (i === index) {
        return { ...image, isSelected: !image.isSelected };
      }
      return image;
    });

    setSelectedImages(updatedImages.filter(image => image.isSelected));
    handleChange('images', updatedImages, 'person');
  };

  const handleImageClick = (index, image) => {
    setSelectedImage({ index, image });
  };
  
  const handleNext = () => {
    const nextIndex = selectedImage.index + 1;
    if (nextIndex < personObject.images.length) {
      setSelectedImage({ index: nextIndex, image: personObject.images[nextIndex] });
    }
  };
  
  const handlePrev = () => {
    const prevIndex = selectedImage.index - 1;
    if (prevIndex >= 0) {
      setSelectedImage({ index: prevIndex, image: personObject.images[prevIndex] });
    }
  };  

  const handleCloseOverlay = () => {
    // Close the overlay by resetting the selected image
    setSelectedImage(null);
  };

  const deleteImages = () => {
    setEditImages(false);
    const gallery = personObject.images.filter(image => !image.isSelected);
    handleChange('images', gallery, 'person');
  }


  return (
    <div className='profile-inputs padding-40-bottom'>
        {personObject.id && 
          <CastingDati 
            person={personObject}
            setValue={handleChange}
          />
        }


        <PersonasDati
          person={personObject}
          setValue={handleChange}
        />

        <GimenesDati 
          person={personObject}
          birthdate={personObject.birthdate}
          setValue={handleChange}
        />

        <KontaktuDati 
          person={personObject}
          features={featuresObject}
          setValue={handleChange}
          transformFeature={transformFeature}
        />
      
        <IpasibasDati 
            person={personObject}
            features={featuresObject}
            setValue={handleChange}
            transformFeature={transformFeature}
        />
        <AktiermakslasDati 
          person={personObject}
          features={featuresObject}
          setValue={handleChange}
          transformFeature={transformFeature}
        />
        <PrasmesDati
          features={featuresObject}
          setValue={handleChange}
          transformFeature={transformFeature}
        />
        <DarbsIzglitibaDati 
          person={personObject}
          features={featuresObject}
          setValue={handleChange}
          transformFeature={transformFeature}
        />
        <VelmesDati 
          features={featuresObject}
          setValue={handleChange}
          transformFeature={transformFeature}
        />

        <ImageData setValue={handleChange} thumbnail={personObject.profile_image}/>
        <MultipleImages setValue={handleChange} gallery={personObject.images}/>

        <div className="input-form-container">
          
          <div className="gallery-controls">
            <Gallery
              images={personObject.images}
              enableImageSelection={admin ? true : false}
              rowHeight={200}
              {...(editImages && admin ? { onSelect: handleSelect } : {})}
              {...(!editImages && admin ? { onClick: handleImageClick } : {})}
              />
          
            <ImageOverlay
              selectedImage={selectedImage}
              handleCloseOverlay={handleCloseOverlay}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          </div>
        
          {/* <button className='submit-button submit-inputs' type="button" style={{font:"inherit"}} onClick = {() => submit()}>Saglabāt un nosūtīt</button> */}
          {admin &&
          <>
            <div className="padding-20-top"></div>
            {editImages ? (
              <>
                <button className='submit-button delete-images' type="button" onClick = {() => deleteImages()}>Dzēst izvēlētās</button>
              </>) : (
              <>
                <button className='submit-button delete-images' type="button" onClick = {() => setEditImages(!editImages)}>Rediģēt galeriju</button>
              </>
            )}
          </>
        }
          {
            personObject.id ?
              <button className='submit-button submit-inputs' type="button" style={{font:"inherit"}} onClick = {() => submit()}>Atjaunot</button>
            :
              <button className='submit-button submit-inputs' type="button" style={{font:"inherit"}} onClick = {() => submit()}>Nosūtīt</button>
          }
        </div>
        
    </div>
  );
}

export default InputForm;