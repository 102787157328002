import { useEffect, useState } from 'react';
import { getProjects, getProjectPeople, getSelectedPeople, reset } from '../../features/projects/projectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import SelectedPeopleList from '../../components/SelectedPeopleList';
import ProjectsList from '../../components/ProjectsList';
import { FaPlus, FaMinus } from 'react-icons/fa';
import NewProjectForm from '../../components/NewProjectForm';

function Projects() {
    const { user } = useSelector((state) => state.auth);
    const { projects, people, isLoading, isError, message } = useSelector((state) => state.project);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [peopleObjects, setPeopleObjects] = useState([]);
    const [creatingProject, setCreatingProject] = useState(false);
    const [addingToProject, setAddingToProject] = useState(false);

    const [selectedPeople, setSelectedPeople] = useState(() => {
        const savedPeople = localStorage.getItem('selectedPeople');
        return savedPeople ? JSON.parse(savedPeople) : [];
    });

    const removeSelection = (personId) => {
        setPeopleObjects(peopleObjects.filter((person) => person !== personId));
        setSelectedPeople(selectedPeople.filter((person) => person !== personId));
        localStorage.setItem('selectedPeople', JSON.stringify(selectedPeople.filter((person) => person !== personId)));
    }

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
        if (isError) {
            toast.error(message);
        }
        dispatch(getProjects());
    }, [user, isError, message, dispatch, navigate]);

    useEffect(() => {
        if (selectedPeople.length > 0) {
            dispatch(getSelectedPeople(selectedPeople));
        }
    }, [selectedPeople, dispatch]);

    useEffect(() => {
        if (people) {
            const selected_people = people.filter((person) => selectedPeople.some((selected) => selected.person_id === person.id));
            setPeopleObjects(selected_people);
        }
    }, [people]);

    useEffect(() => {
        localStorage.setItem('selectedPeople', JSON.stringify(selectedPeople));
    }, [selectedPeople]);

    const resetForm = () => {
        setCreatingProject(false);
        setAddingToProject(false);
        setPeopleObjects([]);
        setSelectedPeople([]);
        localStorage.removeItem('selectedPeople');
    }

    if (isLoading || !projects) {
        return <Spinner />
    }
  return (
    <div className='projects-page'>
        <div className="flex center-v gap-10 padding-20-top">
            <button className ="plus" onClick={() => {setCreatingProject(!creatingProject); setAddingToProject(false)}}>{!creatingProject ? <FaPlus /> : <FaMinus />}</button>
            <h5 className="light">Jauns projekts</h5>
        </div>

        <div className="flex center-v gap-10 padding-20-top">
            <button className ="plus" onClick={() => {setAddingToProject(!addingToProject); setCreatingProject(false)}}>{!addingToProject ? <FaPlus /> : <FaMinus />}</button>
            <h5 className="light">Pievienot personas projektam</h5>
        </div>

        {creatingProject ? <NewProjectForm people={people} resetForm={resetForm} removeSelection={removeSelection}/> : addingToProject ? <SelectedPeopleList peopleObjects={peopleObjects} resetForm={resetForm} removeSelection={removeSelection}/> : <ProjectsList projects={projects}/>}

        {/* {creatingProject ? <NewProjectForm people={people}/> : !addingToProject && <ProjectsList projects={projects} />} 
        {addingToProject && <SelectedPeopleList creatingProject={creatingProject} setCreatingProject={setCreatingProject} peopleObjects={peopleObjects} setPeopleObjects={setPeopleObjects} setSelectedPeople={setSelectedPeople} removeSelection={removeSelection} projects={projects}/>}
        <SelectedPeopleList creatingProject={creatingProject} setCreatingProject={setCreatingProject} peopleObjects={peopleObjects} setPeopleObjects={setPeopleObjects} setSelectedPeople={setSelectedPeople} removeSelection={removeSelection} projects={projects}/> */}
    </div>
  )
}

export default Projects