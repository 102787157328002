import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react'
import ProjectNameDropdown from './ProjectNameDropdown';
import { getProjects, addToProject } from '../features/projects/projectSlice';
import { FaAngleRight, FaPlus, FaMinus } from 'react-icons/fa';
import SelectedPeopleGrid from './SelectedPeopleGrid';
import { toast } from 'react-toastify';

function ProjectPeopleList({peopleObjects, removeSelection, resetForm}) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedProject, setSelectedProject] = useState("");
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [newProject, setNewProject] = useState(false);

    const { projects, isLoading, isError, message } = useSelector((state) => state.project);
    
    const dispatch = useDispatch();

    useEffect(() => {
        getProjects();
        if (projects) {
            setFilteredProjects(projects);
        }
    }, [projects])

    const handleProjectSelect = (project_name) => {
        setNewProject(false);
        setSelectedProject(project_name);
    }

    const handleInputChange = (e) => {
        setNewProject(true);
        setSelectedProject(e.target.value);
        const filtered = projects.filter(project => 
            project.title.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
        setFilteredProjects(filtered);
    }

    const editProject = () => {
        console.log(peopleObjects);

        const simplifiedPeopleObjects = peopleObjects.map(person => ({
            person_id: person.person_data.id,
            person_name: person.person_data.name,
        }));

        const projectObject = projects.find(project => project.title === selectedProject);
        if(!projectObject) {
            toast.error("Kaut kas nogāja greizi, projekts nav atrasts");
            return;
        }
        
        dispatch(addToProject({ projectId: projectObject._id, people: simplifiedPeopleObjects }));
        resetForm();
    }

  return (
    <div className="selected-people">
        <div className="dropdown-container width-50 padding-20-top">
            <input
                className="project-input"
                type="text"
                name="projekts"
                value={selectedProject}
                onClick={() => setShowDropdown(!showDropdown)}
                onChange={handleInputChange}
                placeholder="Izvēlies/Izveido Projektu"
            />
            <ProjectNameDropdown projects = {filteredProjects} showDropdown={showDropdown} handleValueSelect={handleProjectSelect} setShowDropdown={setShowDropdown} />
            <div className="padding-10-top"></div>
            <button className="button-basic" onClick={editProject}>Button</button>
            <div className="padding-20-top"></div>
        </div>
        <span>Izvēlētās personas:</span>
        <SelectedPeopleGrid people={peopleObjects} removeSelection={removeSelection}/>                
    </div>
  )
}

export default ProjectPeopleList