import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { Gallery } from 'react-grid-gallery';
import { getSinglePersonPublic, reset } from "../../features/people/personSlice";
import ImageOverlay from '../../components/ImageOverlay'; // Ensure you have this component

function PublicPerson() {
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const { person, isLoading } = useSelector((state) => state.person);

  const [personObject, setPersonObject] = useState(null);
  const [featuresObject, setFeaturesObject] = useState(null);
  const [personThumbnail, setPersonThumbnail] = useState(null);
  const [personImages, setPersonImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // Overlay state
  const [selectedImages, setSelectedImages] = useState([]); // Selection state

  useEffect(() => {
    if (profileId) {
      dispatch(getSinglePersonPublic(profileId));
    }
  }, [profileId, dispatch]);

  useEffect(() => {
    if (person?.person) {
      setPersonObject(person.person);
      setPersonThumbnail(person.thumbnail);
      setPersonImages(person.images);
      setFeaturesObject(person.features);
    }

    return () => {
      dispatch(reset());
    };
  }, [person]);

  if (isLoading) {
    return <Spinner />;
  }

  const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSelect = (index) => {
    const updatedImages = personImages.map((image, i) => {
      if (i === index) {
        return { ...image, isSelected: !image.isSelected };
      }
      return image;
    });

    setSelectedImages(updatedImages.filter(image => image.isSelected));
    setPersonImages(updatedImages);
  };

  const handleImageClick = (index, image) => {
    setSelectedImage({ index, image });
  };

  const handleNext = () => {
    const nextIndex = selectedImage.index + 1;
    if (nextIndex < personImages.length) {
      setSelectedImage({ index: nextIndex, image: personImages[nextIndex] });
    }
  };

  const handlePrev = () => {
    const prevIndex = selectedImage.index - 1;
    if (prevIndex >= 0) {
      setSelectedImage({ index: prevIndex, image: personImages[prevIndex] });
    }
  };

  const handleCloseOverlay = () => {
    setSelectedImage(null);
  };

  if (!personObject) {
    return <Spinner />;
  }

  return (
    <div className="flex column">
      <div className="public-person padding-40-top">
        <div className="flex space-between">
          <div className="public-person-content padding-20-bottom">
            <h4 className="light">{personObject.name}</h4>
            <ul className="padding-10-top" style={{ paddingLeft: "20px" }}>
              <li>Vecums: {getAge(personObject.birthdate)}</li>
              <li>Augums: {personObject.height} cm</li>
              <li>Apavu izmērs: {personObject.shoe_size}</li>
              <li>Apģērba izmērs: {personObject.clothing_size}</li>
              {featuresObject.languageskill ? (
                <li>
                  Valodas prasmes:
                  <ul>
                    {personObject.languages.map((lang) => (
                      <li key={lang.id}>{lang.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
              <li>Pilsēta: {personObject.city}</li>
              <li>Matu krāsa: {featuresObject.haircolor[0].values}</li>
              <li>Acu krāsa: {featuresObject.eyecolor[0].values}</li>
              {featuresObject.danceskill ? (
                <li>
                  Dejotprasme:
                  <ul>
                    {featuresObject.danceskill.map((dance) => (
                      <li key={dance.id}>{dance.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
              {featuresObject.singskill ? (
                <li>
                  Dziedātprasme:
                  <ul>
                    {featuresObject.singskill.map((sing) => (
                      <li key={sing.id}>{sing.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
              {featuresObject.musicskill ? (
                <li>
                  Mūzikas prasmes:
                  <ul>
                    {featuresObject.musicskill.map((music) => (
                      <li key={music.id}>{music.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
              {featuresObject.sportskill ? (
                <li>
                  Sporta prasmes:
                  <ul>
                    {featuresObject.sportskill.map((sport) => (
                      <li key={sport.id}>{sport.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
              {featuresObject.otherskill ? (
                <li>
                  Citas prasmes:
                  <ul>
                    {featuresObject.otherskill.map((other) => (
                      <li key={other.id}>{other.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
              {featuresObject.driveskill ? (
                <li>
                  Braukšanas tiesības:
                  <ul>
                    {featuresObject.driveskill.map((drive) => (
                      <li key={drive.id}>{drive.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
              {featuresObject.tattoo ? (
                <li>
                  Tetovējumi:
                  <ul>
                    {featuresObject.tattoo.map((tattoo) => (
                      <li key={tattoo.id}>{tattoo.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
              {featuresObject.piercing ? (
                <li>
                  Pīrsingi:
                  <ul>
                    {featuresObject.piercing.map((piercing) => (
                      <li key={piercing.id}>{piercing.values}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
            </ul>
          </div>

          <div className="public-person-media">
            <img src={personThumbnail.src} alt={personObject.name} />
          </div>
        </div>
      </div>
      <div className="flex column padding-40-bottom">
        <h4 className="light padding-10-bottom">Galerija:</h4>
        <Gallery
          images={personImages}
          enableImageSelection={true} // Enable selection
          rowHeight={200}
          onSelect={handleSelect} // Selection handler
          onClick={handleImageClick} // Click handler for overlay
        />

        <ImageOverlay
          selectedImage={selectedImage}
          handleCloseOverlay={handleCloseOverlay}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      </div>
    </div>
  );
}

export default PublicPerson;
