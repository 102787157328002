import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import projectService from './projectService';
import { toast } from 'react-toastify'

const initialState = {
  projects: [],
  people: [],
  project_person: null,
  spreadsheet: null,
  project: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get projects
export const getProjects = createAsyncThunk(
  'projects/',
  async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await projectService.getProjects(token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSelectedPeople = createAsyncThunk(
  'projects/people',
  async (people, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await projectService.getSelectedPeople(people, token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get projects
export const getProject = createAsyncThunk(
  'project/:id',
  async (id, thunkAPI) => {
    try {
        return await projectService.getProject(id)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Color
export const updateColor = createAsyncThunk(
  'project/color/:id',
  async ({id, color}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await projectService.updateColor(id, color, token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add new project
export const addProject = createAsyncThunk(
  'projects/new',
  async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await projectService.addProject(data, token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete person from project
export const removeFromProject = createAsyncThunk(
  'projects/:id',
  async ({projectId, personId}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await projectService.removeFromProject(projectId, personId, token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// add people to project
export const addToProject = createAsyncThunk(
  'projects/add-people/:id',
  async ({projectId, people}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await projectService.addToProject(projectId, people, token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete project
export const deleteProject = createAsyncThunk(
  'projects/delete/:id',
  async (projectId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await projectService.deleteProject(projectId, token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Redux slice
export const editProject = createAsyncThunk(
  'projects/edit/:id',
  async ({projectId, data}, thunkAPI) => {
    try {
        console.log(projectId, data)
        const token = thunkAPI.getState().auth.user.token
        return await projectService.editProjectService(projectId, data, token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Redux slice
export const addPersonComment = createAsyncThunk(
  'projects/person-comment/:id',
  async ({id, person_id, comment}, thunkAPI) => {
    try {
        console.log(id, person_id, comment)
        return await projectService.addPersonComment(id, person_id, comment)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Redux slice
export const updateLiked = createAsyncThunk(
  'projects/person-liked/:id',
  async ({id, person_id}, thunkAPI) => {
    try {
        console.log(id, person_id)
        return await projectService.updateLiked(id, person_id)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get spreadsheet
export const getSpreadsheet = createAsyncThunk(
  'projects/:id/export',
  async (projectId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await projectService.getSpreadsheet(projectId, token)
    } catch (error) {
      const message =

        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get project person
export const getProjectPerson = createAsyncThunk(
  'projects/:projectId/:personId',
  async ({projectId, profileId}, thunkAPI) => {
    try {
        return await projectService.getProjectPerson(projectId, profileId)
    } catch (error) {
      const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
}
);

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reset: (state) => {
      state.person = initialState.person;
      state.people = initialState.people;
      state.pages = initialState.pages;
      state.isError = initialState.isError;
      state.isSuccess = initialState.isSuccess;
      state.isLoading = initialState.isLoading;
      state.message = initialState.message;
      state.project_person = initialState.project_person;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getProjects.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getProjects.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.projects = action.payload
    })
    .addCase(getProjects.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.projects = [] // Changed from null to []
    })
    .addCase(addProject.pending, (state) => {
      state.isLoading = true
    })
    .addCase(addProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.projects = [...state.projects, action.payload]
    })
    .addCase(addProject.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.projects = [] // Changed from null to []
    })
    .addCase(getProject.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.projects = [...state.projects, action.payload]
      state.project = action.payload
    })
    .addCase(getProject.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.projects = [] // Changed from null to []
      state.project = null
    })
    .addCase(updateColor.pending, (state) => {
      state.isLoading = true
    })
    .addCase(updateColor.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.projects = [...state.projects, action.payload]
      state.project = action.payload
    })
    .addCase(updateColor.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.projects = [] // Changed from null to []
      state.project = null
    })
    .addCase(getSelectedPeople.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getSelectedPeople.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.people = action.payload
    })
    .addCase(getSelectedPeople.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.people = null
    })
    .addCase(removeFromProject.pending, (state) => {
      state.isLoading = true
    })
    .addCase(removeFromProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.project = action.payload
    })
    .addCase(removeFromProject.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.people = null
      state.project = null
    })
    .addCase(addToProject.pending, (state) => {
      state.isLoading = true
    })
    .addCase(addToProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.project = action.payload
      toast.success('Personas pievienotas projektam')
    })
    .addCase(addToProject.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.people = null
      state.project = null
    })
    .addCase(deleteProject.pending, (state) => {
      state.isLoading = true
    })
    .addCase(deleteProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.projects = state.projects.filter((project) => project._id !== action.payload._id)
    })
    .addCase(deleteProject.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.people = null
      state.project = null
    })
    .addCase(editProject.pending, (state) => {
      state.isLoading = true
    })
    .addCase(editProject.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.project = action.payload
    })
    .addCase(editProject.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.people = null
      state.project = null
    })
    .addCase(addPersonComment.pending, (state) => {
      state.isLoading = true
    })
    .addCase(addPersonComment.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.projects = [...state.projects, action.payload]
      state.project = action.payload
    })
    .addCase(addPersonComment.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.projects = [] // Changed from null to []
      state.project = null
    })

    .addCase(updateLiked.pending, (state) => {
      state.isLoading = true
    })
    .addCase(updateLiked.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.projects = [...state.projects, action.payload]
      state.project = action.payload
    })
    .addCase(updateLiked.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.projects = [] // Changed from null to []
      state.project = null
    })
    .addCase(getSpreadsheet.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getSpreadsheet.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.spreadsheet = action.payload
    })
    .addCase(getSpreadsheet.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.spreadsheet = null
    })
    .addCase(getProjectPerson.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getProjectPerson.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.project_person = action.payload
    })
    .addCase(getProjectPerson.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.project_person = null
    })
    
  },
})

export const { reset } = projectSlice.actions
export default projectSlice.reducer