import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../components/Spinner'
import { getPeople, reset } from '../../features/people/personSlice';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import FilterInput from '../../components/FilterInput'
import ProfilesGrid from '../../components/admin/ProfilesGrid'

function Profiles({unverified = false}) {
    const { user } = useSelector((state) => state.auth)

    const { person, pages,  people, isLoading, isError, message } = useSelector(
        (state) => state.person
    )

    const [userType, setUserType] = useState(null)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    
    const currentPage = parseInt(params.get('page')) || 1;

    const name_filter = params.get('name_filter') ? decodeURIComponent(params.get('name_filter')) : null;
    const surname_filter = params.get('surname_filter') ? decodeURIComponent(params.get('surname_filter')) : null;
    const email_filter = params.get('email_filter') ? decodeURIComponent(params.get('email_filter')) : null;
    const gender_filter = params.get('gender_filter') || null
    const speciality_filter = params.get('speciality_filter') || null
    const ageFrom_filter = parseInt(params.get('ageFrom_filter')) || null
    const ageTo_filter = parseInt(params.get('ageTo_filter')) || null
    const paid_for_filter = params.get('paid_for_filter') || null
    const phone_filter = parseInt(params.get('phone_filter')) || null
    const heightFrom_filter = parseInt(params.get('heightFrom_filter')) || null
    const heightTo_filter = parseInt(params.get('heightTo_filter')) || null
    const id_filter = params.get('id_filter') || null
    const allTags_filter = params.get('allTags_filter') ? decodeURIComponent(params.get('allTags_filter')) : null;

    const combineFilters = useCallback(() => {
        return { 
            name_filter,
            surname_filter,
            email_filter,
            gender_filter,
            speciality_filter,
            ageFrom_filter,
            ageTo_filter,
            phone_filter,
            heightFrom_filter,
            heightTo_filter,
            paid_for_filter,
            allTags_filter,
            id_filter,
            unverified_filter: unverified ? true : null
        };
    }, [unverified, id_filter, allTags_filter, name_filter, surname_filter, email_filter, gender_filter, speciality_filter, ageFrom_filter, ageTo_filter, phone_filter, heightFrom_filter, heightTo_filter, paid_for_filter]);
    


    useEffect(() => {
        if (!user) {
            navigate('/login');
        } else{
            setUserType(user.type);
        }
        if (isError) {
            toast.error("Kaut kas nogāja greizi...");
        }
    }, [user, person, people, isError, message, dispatch, navigate]);
    
    useEffect(() => {
        const filters = combineFilters(); 
        dispatch(getPeople({ page: currentPage, filters }));
    }, [dispatch, combineFilters, id_filter, allTags_filter, unverified, paid_for_filter, currentPage, gender_filter, email_filter, speciality_filter, name_filter, surname_filter, phone_filter, heightFrom_filter, heightTo_filter, ageFrom_filter, ageTo_filter, navigate]);

    const pageRight = () => {
        if (currentPage < pages) {
            const nextPage = currentPage + 1;
            const filters = combineFilters();
    
            // Construct query string
            const queryString = Object.keys(filters)
                .map(key => (filters[key] !== null && filters[key] !== "") ? `${key}=${encodeURIComponent(filters[key])}` : '')
                .filter(Boolean)
                .join('&');
    
            navigate(`/profiles?page=${nextPage}${queryString ? `&${queryString}` : ''}`);
        }
    };
    
    const pageleft = () => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            const filters = combineFilters();
    
            // Construct query string
            const queryString = Object.keys(filters)
                .map(key => (filters[key] !== null && filters[key] !== "") ? `${key}=${encodeURIComponent(filters[key])}` : '')
                .filter(Boolean)
                .join('&');
    
            navigate(`/profiles?page=${prevPage}${queryString ? `&${queryString}` : ''}`);
        }
    };
    


    const clearFilters = () => {
        navigate('/profiles');
    }
    
    if (isLoading || !people) {
        return <Spinner />
    }
    
    return (
        <div className="profiles-page">
            {unverified ?
              <div>
                <h3 className='light padding-10-bottom'>Datubāzes pieteikumi</h3>
              </div>
              :
              <>
                <FilterInput 
                    clearFilters={clearFilters}
                    initialGender={gender_filter}
                    initialSpeciality={speciality_filter}
                    initialName={name_filter}
                    initialSurname={surname_filter}
                    initialPhone={phone_filter}
                    initialEmail={email_filter}
                    initialHeightFrom={heightFrom_filter}
                    initialHeightTo={heightTo_filter}
                    initialAgeFrom={ageFrom_filter}
                    initialAgeTo={ageTo_filter}
                    initialPaidFor={paid_for_filter}
                    initialAllTags={allTags_filter}
                    initialIdFilter={id_filter}
                />
                <div className="profiles-controls">
                  <div className="profiles-controls-buttons">
                      <button onClick={pageleft}><FaAngleLeft/></button>
                      <p>{currentPage} no {pages}</p>
                      <button onClick = {pageRight}><FaAngleRight/></button>
                  </div>
                </div>
              </>
              
            }
            
            <ProfilesGrid people={people} unverified={unverified} userType={userType}/>
        </div>
    )
}
export default Profiles
