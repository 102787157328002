import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import classifierService from './classifierService'

const initialState = {
  category: null,
  categories: [],
  values: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get classifier category values
export const getValues = createAsyncThunk(
  'classifiers/:category',
  async (class_category, thunkAPI) => {
    try {
        //const token = thunkAPI.getState().auth.user.token
        return await classifierService.getValues(class_category) //getValues(token, category)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get categories
export const getCategories = createAsyncThunk(
  'classifiers/',
  async (thunkAPI) => {
    try {
      return await classifierService.getCategories()
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete classifier value
export const deleteValue = createAsyncThunk(
  'classifiers/:category/:value',
  async ({category, value}, thunkAPI) => {
    try {
      return await classifierService.deleteValue(category, value)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// // Get single person public
// export const getSinglePersonPublic = createAsyncThunk(
//     'people/public/:id',
//     async (id, thunkAPI) => {
//       try {
//         return await personService.getPerson(id)
//       } catch (error) {
//         const message =
//           (error.response && error.response.data && error.response.data.message) ||
//           error.message ||
//           error.toString();
//         return thunkAPI.rejectWithValue(message);
//       }
//     }
//   );

// // Get all people private
// export const getPeople = createAsyncThunk(
//   'people/',
//   async (page, thunkAPI) => {
//     try {
//       const token = thunkAPI.getState().auth.user.token
//       return await personService.getPeople(token, page)
//     } catch (error) {
//       const message =
//         (error.response && error.response.data && error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// // Get all people private
// export const getPeoplePublic = createAsyncThunk(
//     'people/public/',
//     async (page, thunkAPI) => {
//       try {
//         const token = thunkAPI.getState().auth.user.token
//         return await personService.getPeoplePublic(page)
//       } catch (error) {
//         const message =
//           (error.response && error.response.data && error.response.data.message) ||
//           error.message ||
//           error.toString();
//         return thunkAPI.rejectWithValue(message);
//       }
//     }
//   );

export const classifierSlice = createSlice({
  name: 'classifier',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.category = null
      state.values = []
    },
    close: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.category = null
      state.values = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getValues.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getValues.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.category = action.payload.category
        state.values = action.payload.values
      })
      .addCase(getValues.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.category = null
        state.values = null
      })
      .addCase(getCategories.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.categories = action.payload
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.categories = []
      })
      .addCase(deleteValue.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteValue.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.category = action.payload.category
        state.values = action.payload.values
      })
      .addCase(deleteValue.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.category = null
        state.values = null
      })
      
      // .addCase(getSinglePersonPublic.pending, (state) => {
      //   state.isLoading = true
      // })
      // .addCase(getSinglePersonPublic.fulfilled, (state, action) => {
      //   state.isLoading = false
      //   state.isSuccess = true
      //   state.person = action.payload
      //   state.people = null
      // })
      // .addCase(getSinglePersonPublic.rejected, (state, action) => {
      //   state.isLoading = false
      //   state.isError = true
      //   state.message = action.payload
      //   state.person = null
      //   state.people = null
      // })
      // .addCase(getPeople.pending, (state) => {
      //   state.isLoading = true
      // })
      // .addCase(getPeople.fulfilled, (state, action) => {
      //   state.isLoading = false
      //   state.isSuccess = true
      //   state.person = action.payload[0]
      //   state.people = action.payload
      // })
      // .addCase(getPeople.rejected, (state, action) => {
      //   state.isLoading = false
      //   state.isError = true
      //   state.message = action.payload
      //   state.person = null
      //   state.people = null
      // })
      // .addCase(getPeoplePublic.pending, (state) => {
      //   state.isLoading = true
      // })
      // .addCase(getPeoplePublic.fulfilled, (state, action) => {
      //   state.isLoading = false
      //   state.isSuccess = true
      //   state.person = action.payload[0]
      //   state.people = action.payload
      // })
      // .addCase(getPeoplePublic.rejected, (state, action) => {
      //   state.isLoading = false
      //   state.isError = true
      //   state.message = action.payload
      //   state.person = null
      //   state.people = null
      // })
  },
})

export const { reset, close } = classifierSlice.actions
export default classifierSlice.reducer