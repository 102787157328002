import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { getProject, getSpreadsheet, reset, updateColor } from '../../features/projects/projectSlice';
import { useParams } from 'react-router-dom';
import ProjectProfileCard from '../../components/ProjectProfileCard';
import { GithubPicker } from 'react-color';
import EditProjectForm from '../../components/EditProjectForm';

const colors = ['#DDBDD5', '#AA4465', '#5DA399', '#CDE77F', '#F0F3BD', '#7FD1B9', '#ACEDFF', '#FCAA67'];

function Project() {
  const { projectId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { project, spreadsheet, isLoading, isError, message } = useSelector((state) => state.project);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [color, setColor] = useState('#fff');

  const handleChange = (color, event) => {
    setColor(color.hex);
    dispatch(updateColor({ id: projectId, color: color.hex }));
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }

    if (isError) {
      toast.error(message);
    }

    if (project) {
      console.log(project);
      if (project.color) {
        setColor(project.color);
      }
    }

    return () => {
      dispatch(reset());
    };
  }, [user, project, isError, message, dispatch, navigate]);

  useEffect(() => {
    dispatch(getProject(projectId));
  }, [dispatch, projectId]);

  // Function to download the file
  const handleOnExport = () => {
    dispatch(getSpreadsheet(projectId));
  };

  // Effect to handle file download when spreadsheet data is available
  useEffect(() => {
    if (spreadsheet) {
      const blob = new Blob([spreadsheet], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'project_export.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }, [spreadsheet]);

  const [editInfo, setEditInfo] = useState(false);

  if (isLoading || !project) {
    return <Spinner />;
  }

  return (
    <div className='project'>
      <div className="">
        <div className='project-title' style={{ display: 'inline-block', backgroundColor: color || "#d3d3d3", padding: "10px 40px" }}>
          <h1 className='light ' style={{ fontSize: "35px" }}>{project.title}</h1>
        </div>
        <p className='padding-10-top'>Projekta vietne: <a onClick={() => navigate(`/projekti/public/${projectId}`)}><b>{window.location.href.replace(/projekti\/([^\/]*)$/, 'projekti/public/$1')}</b></a></p>
        <div className="project-page-controls">
          {!editInfo ?
            <div className='flex gap-10 padding-20-top'>
              <button className="button-basic" onClick={() => setEditInfo(!editInfo)}>{!editInfo ? "Rediģēt info" : "Atcelt"}</button>
              <button className="button-basic" onClick={handleOnExport}>Excel Eksports</button>
            </div>
            : null}
        </div>
      </div>

      {editInfo ?
        <>
          <div className="padding-10-top"></div>
          <span>Projekta krāsa:</span>
          <GithubPicker color={color} onChange={handleChange} colors={colors} />
          <EditProjectForm editInfo={editInfo} titleValue={project.title} peopleValues={project.people_values} projectId={projectId} setEditInfo={setEditInfo} />
        </>
        :
        <div className='profile-grid padding-20-top'>
          {project ? project.people ? (
            <>
              {project.people.map((profile, index) => (
                <ProjectProfileCard key={index} profile={profile} onPage={true} projectId={project._id} />
              ))}
            </>
          ) : null : null}
        </div>
      }
    </div>
  );
}

export default Project;
