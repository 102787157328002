import React from 'react'
import { Link } from 'react-router-dom'

function ApplyChoice() {
  return (
    <div className="apply-choice">
        <h4 className='padding-20-bottom'>Pieteikšanās process</h4>
        <div className="choice-cards">
            <Link to="/apply-input" className="choice-card-link">
            <div className="choice-card">
                <div className="choice-card-info">
                    <h5>Pieteikšanās mūsu mājaslapā</h5>
                    <ul className='choice-card-list padding-10-top'>
                        <li>Pats pievieno profila galeriju</li>
                        <li>Piesakies Casting datubāzei no mājām</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Consectetur adipiscing elit</li>
                    </ul>
                </div>
                
                <div className="choice-price">
                    Cena: 0.00EUR
                </div>
            </div>
            </Link>
            <Link to="/apply" className="choice-card-link">
            <div className="choice-card">
                <div className="choice-card-info">
                    <h5>Pieteikšanās CastingBridge birojā</h5>
                    <ul className='choice-card-list padding-10-top'>
                        <li>Palīdzēsim izveidot profesionālu galeriju</li>
                        <li>Pārliecinies, ka visa informācija tiek ievadīta pareizi</li>
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Consectetur adipiscing elit</li>
                    </ul>
                </div>
                <div className="choice-price">
                    Cena: 20.00EUR
                </div>
            </div>
            </Link>
        </div>
        
    </div>
  )
}

export default ApplyChoice
