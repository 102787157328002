import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, getProjectPeople, reset } from '../features/projects/projectSlice';
import { toast } from 'react-toastify';
import Spinner from './Spinner';


function ProjectNameDropdown({ projects, showDropdown, handleValueSelect, setShowDropdown }) {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDropdown]);

  const handleSelect = (value) => {
    handleValueSelect(value);
    setShowDropdown(false);
  };

  if (!showDropdown) {
    return null;
  }

  return (
    <div className="dropdown" ref={dropdownRef}>
        {projects ? 
        <ul>
        {projects.map((project) => (
          <li key={project._id} onClick={() => handleSelect(project.title)}> 
            {project.title}
          </li>
        ))} 
      </ul> : null}
      
    </div>
  );
}

export default ProjectNameDropdown;