import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { getSinglePersonPublic, reset } from '../../features/people/personSlice';
import { Gallery } from 'react-grid-gallery';

function PersonGallery() {
  const { profileId } = useParams();
  const dispatch = useDispatch();

  const { person, isLoading, isError, message } = useSelector((state) => state.person);

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if(person && person.images){
        setGallery(person.images)
    }

    return () => {
      dispatch(reset());
    };
  }, [person, isError, message, dispatch]);

  useEffect(() => {
    if(profileId){
      dispatch(getSinglePersonPublic(profileId));
    }
  }, [dispatch, profileId]);

  if(isLoading){
    return <Spinner />
  }

  return (
    <div className='padding-20-top padding-20-bottom'>
      <Gallery images={gallery} enableImageSelection={false} />
    </div>
  )
}

export default PersonGallery



// import { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import Spinner from '../../components/Spinner';
// import { getSinglePersonPublic, reset } from '../../features/people/personSlice';
// import { useParams } from 'react-router-dom';
// import { Gallery } from "react-grid-gallery";
// import ImageOverlay from '../../components/ImageOverlay';

// function PersonGallery() {
//   const { profileId } = useParams();

//   const { user } = useSelector((state) => state.auth);

//   const { person, isLoading, isError, message } = useSelector(
//     (state) => state.person
//   );

//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const [gallery, setGallery] = useState([]);

//   useEffect(() => {
//     if (!user) {
//       navigate('/login');
//     }

//     if (isError) {
//       toast.error(message);
//     }

//     if(person){
//         setGallery(person.images)
//     }

//     return () => {
//       dispatch(reset());
//     };
//   }, [user, person, isError, message, dispatch, navigate]);

//   useEffect(() => {
//     dispatch(getSinglePersonPublic(profileId));
//   }, [dispatch, profileId]);


//   const [selectedImage, setSelectedImage] = useState(null);

//   const handleImageClick = (index, image) => {
//     setSelectedImage({ index, image });
//   };
  
//   const handleNext = () => {
//     const nextIndex = selectedImage.index + 1;
//     if (nextIndex < person.images.length) {
//       setSelectedImage({ index: nextIndex, image: person.images[nextIndex] });
//     }
//   };
  
//   const handlePrev = () => {
//     const prevIndex = selectedImage.index - 1;
//     if (prevIndex >= 0) {
//       setSelectedImage({ index: prevIndex, image: person.images[prevIndex] });
//     }
//   };  

//   const handleCloseOverlay = () => {
//     setSelectedImage(null);
//   };

//   console.log(person)


//   if(!person){
//       return <Spinner />
//   }

//   return (
//     <div className="public-profile">
        
        
//     </div>
//   );
// }

// export default PersonGallery;

