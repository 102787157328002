import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ImageOverlay = ({ selectedImage, handleCloseOverlay, handleNext, handlePrev }) => {
  return (
    selectedImage !== null && (
      <div className="overlay overlay-active">
        <div className="overlay-content">
          <button className="close-button" onClick={handleCloseOverlay}>
            <FaTimes />
          </button>
          <img src={selectedImage.image.src} alt={selectedImage.image.alt} />
          <button className="nav-button prev-button" onClick={handlePrev}>
            <FaChevronLeft />
          </button>
          <button className="nav-button next-button" onClick={handleNext}>
            <FaChevronRight />
          </button>
        </div>
      </div>
    )
  );
};

export default ImageOverlay;
