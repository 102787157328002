import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { removeFromProject } from '../features/projects/projectSlice';


function ProjectProfileCard({profile, removeSelection, projectId}) {

  const dispatch = useDispatch();

  const deletePerson = () => {
    dispatch(removeFromProject({projectId:projectId, personId: profile.person_id}))
  }

  return (

    <div className='project-profile-card'>
          <FaTimes 
              className="delete-icon" 
              style={{position: "absolute", top: "10px", right: "10px", cursor: "pointer", padding: "5px", background: "transparent", zIndex: "100"}} 
              onClick={deletePerson}
          />

          {profile.thumbnail ? <img src={profile.thumbnail}/> : "Nothing"}
          <div className="project-profile-card-title">
            <h5 className="padding-20-top">{profile.person_name.split(' ')[0]}</h5>
          </div>
        </div>

  )
}

export default ProjectProfileCard