import { Link } from 'react-router-dom'
import placeholder from '../images/placeholder.png'
import { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import Confirmation from './Confirmation'
import { useDispatch } from 'react-redux'
import { deleteProject } from '../features/projects/projectSlice'

function ProjectCard({project}) {

  const dispatch = useDispatch()
  const created = new Date(project.created)

  const [showConfirmation, setShowConfirmation] = useState(false)
  

  const handleDelete = () => {
    dispatch(deleteProject(project._id))
  }

  const showConfirmationBox = () => {
    setShowConfirmation(true);
  }
  
  return (
    <div className="project-card">
      {showConfirmation && <Confirmation message='Vai esat pārliecināts?' setShow = {setShowConfirmation} action={handleDelete}/>}
      <FaTimes className="close-button-card" onClick={showConfirmationBox} style={{cursor: 'pointer', zIndex: 999, position: 'absolute', top: '10px', right: '10px'}} />
      <Link to={`/projekti/${project._id}`}>
        <div className='project-title-card' style={{backgroundColor: project.color}}>
          <h5>{project.title}</h5>
        </div>
        
        <p>Izveidots: {created.getMonth()}.{created.getFullYear()}</p>
      </Link>
    </div>
      
  )
}

export default ProjectCard
