import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { getProjectPerson, reset } from '../../features/projects/projectSlice';
import { getSinglePersonPublic } from '../../features/people/personSlice';

function ProjectPerson() {
    const { profileId, projectId } = useParams();
    const dispatch = useDispatch();

    const { project_person, isLoading, isError } = useSelector((state) => state.project);
    const { person } = useSelector((state) => state.person);

    useEffect(() => {
        if (profileId && projectId) {
            dispatch(getProjectPerson({projectId, profileId}));
        } else if (profileId) {
            dispatch(getSinglePersonPublic(profileId));
        }
        return () => {
            dispatch(reset());
        };
    }, [projectId, profileId, dispatch]);

    if (isLoading || (projectId && !project_person)) {
        return <Spinner />;
    }

    const profile = project_person.person;
    const features = project_person.features;

    return (
        <div className='public-person padding-40-top'>
            <div className="person-content">
                <h3 className="light">{profile.name}</h3>
                <ul>
                    {profile.height && (
                        <li className='padding-10-top'>
                            Augums: <b>{profile.height}cm</b>
                        </li>
                    )}
                    {profile.age && (
                        <li>
                            Vecums: <b>{profile.age}</b>
                        </li>
                    )}
                    {profile.shoeSize && (
                        <li>
                            Apavu izmērs: <b>{profile.shoeSize}</b>
                        </li>
                    )}
                    {profile.clothingSize && (
                        <li>
                            Apģērba izmērs: <b>{profile.clothingSize}</b>
                        </li>
                    )}
                    {profile.phone && (
                        <li>
                            Telefona numurs: <b>{profile.phone}</b>
                        </li>
                    )}
                    {profile.city && (
                        <li>
                            Pilsēta: <b>{profile.city.map(city => city.values).join(', ')}</b>
                        </li>
                    )}
                </ul>
                <p className='padding-10-top'>(Vairāk informāciju publiskajā profilā mēs norādīt nedrīkstam)</p>
            </div>
            <div className="person-features">
                <h4 className="light padding-20-top">Personas prasmes - atzīmēt visas kuras paturēt:</h4>
                {Object.keys(features).map((key) => (
                    <div key={key} className="feature-category">
                        {features[key].map((feature, index) => (
                            <p key={index} className="light padding-10-top">
                                <input type="checkbox" /><b>{key}: </b>{feature.values}
                            </p>
                        ))}
                    </div>
                ))}
            </div>
            <div className="person-media">
                <h4 className="light padding-10-bottom padding-20-top">Profila bildes:</h4>
                <div className="main-image">
                    <img src={project_person.thumbnail.src} alt="" />
                </div>
                <div className="public-person-gallery padding-40-bottom">
                    {project_person.images.map((image, index) => (
                        <img key={index} src={image.src} alt="" />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjectPerson;