import React, { useState } from 'react';
import image_example from '../images/image_example.jpeg';

function ProfileImageExample({closeOverlay}) {

  return (
    <div className='image-example-overlay'>
      <img src={image_example} alt="" />
      <p>Profila foto ir vēlams uzņemt uz neitrāla fona, dabīgā,
          labā apgaismojumā; sejai ir jābūt labi saredzamai –
          matiem ir jābūt savāktiem uz aizmuguri un novāktiem
          no sejas, kā arī lūdzam novilkt brilles, galvassegu vai
          citas seju nosedzošas detaļas. Fotogrāfija ir jāuzņem,
          kamerai atrodoties tieši pretim sejai.</p>
      <button className="overlay-close-button" onClick={() => closeOverlay()}>Pievienot bildi</button>
    </div>
  )
}

export default ProfileImageExample