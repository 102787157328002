import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import { getProject, addPersonComment, reset } from '../../features/projects/projectSlice';
import { useParams } from 'react-router-dom';
import PublicProjectProfileCard from '../../components/PublicProjectProfileCard';
import * as XLSX from 'xlsx';
import { FaSave, FaTimes } from 'react-icons/fa';

function PublicProject() {
  const { projectId } = useParams();

  const { project, isLoading, isError, message } = useSelector(
    (state) => state.project
  );

  const [projectComment, setProjectComment] = useState('');
  const [personComment, setPersonComment] = useState('');
  const [commentPersonId, setCommentPersonId] = useState('');

  const setAddComment = (personId) => {
    if(commentPersonId == personId) {
      setCommentPersonId('');
      return;
    }
    setPersonComment(project.people.find(person => person.person_id == personId).person_comment);
    setCommentPersonId(personId);
  }

  const handleCommentSave = () => {
    console.log(project._id, commentPersonId, personComment)
    dispatch(addPersonComment({id: project._id, person_id: commentPersonId, comment: personComment}));
    setCommentPersonId('');
    setPersonComment('');
  }

  const closeComment = () => {
    setCommentPersonId('');
    setPersonComment('');
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    return () => {
      dispatch(reset());
    };
  }, [project, isError, message, dispatch]);

  useEffect(() => {
    dispatch(getProject(projectId));
  }, [dispatch, projectId]);

  if (isLoading || !project) {
    return <Spinner />;
  }

  return (
    <div className='project'>   
        <h3 className='light'>{project.title}</h3>
        
        <h2 className="light padding-20-top">Projektam atlasītie cilvēki:</h2>
        <div className='profile-grid padding-10-top'>  
          
          {project ? project.people ? (
              <>
                  {project.people.map((profile, index) => (
                    <div className="profile-container" key={index}>
                      <PublicProjectProfileCard setAddComment={setAddComment}  profile={profile} onPage={true} projectId={project._id}/>
                      {commentPersonId == profile.person_id && 
                        <div>
                          <textarea className="person-comment" name="" value={personComment} id="" onChange={(e) => setPersonComment(e.target.value)}></textarea>
                          <div className="comment-buttons">
                            <button className='button' onClick={closeComment}><FaTimes /></button>
                            <button className='button' onClick={handleCommentSave}><FaSave /></button>
                          </div>
                          
                        </div>
                      }
                      
                    </div>
                      
                  ))}
              </>
          ) : null : null}
        </div>

        
        
    </div>
  );
}

export default PublicProject;