import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addProject } from '../features/projects/projectSlice';
import SelectedPeopleGrid from './SelectedPeopleGrid';
import { toast } from 'react-toastify';

function NewProjectForm({ people, removeSelection, resetForm }) {
    const [title, setTitle] = useState('');

    // Replace the old state values with the new ones from EditProjectForm
    const [showAge, setShowAge] = useState(false);
    const [showHeight, setShowHeight] = useState(false);
    const [showShoeSize, setShowShoeSize] = useState(false);
    const [showClothingSize, setShowClothingSize] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [showLanguages, setShowLanguages] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        const savedPeople = JSON.parse(localStorage.getItem('selectedPeople'));

        if (!savedPeople || savedPeople.length === 0) {
            toast.error("Lai izveidotu projektu, tam jāpievieno personas.");
            return;
        } else if (title === '' || !title) {
            toast.error("Lai izveidotu projektu, tam jāpievieno nosaukums.");
            return;
        }

        const project = {
            title: title,
            people: savedPeople,
            people_values: {
                showAge: showAge,
                showHeight: showHeight,
                showShoeSize: showShoeSize,
                showClothingSize: showClothingSize,
                showPhone: showPhone,
                showLanguages: showLanguages,
            }
        };

        dispatch(addProject(project));
        resetForm();
    };

    return (
        <div className='new-project-form'>
            <form className='input-form padding-20-top'>
                <div className="form-input">
                    <label>Projekta nosaukums</label>
                    <input type="text" name="name" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="info-box">
                    <label>Informācija ko uzrādīt par talantu:</label>
                    <div className="padding-10-top"></div>
                    <ul>
                        <li>
                            <input type="checkbox" name="showHeight" checked={showHeight} onChange={(e) => setShowHeight(e.target.checked)} />
                            <span className='padding-10-left'><b>Augums</b></span>
                        </li>
                        <li>
                            <input type="checkbox" name="showShoeSize" checked={showShoeSize} onChange={(e) => setShowShoeSize(e.target.checked)} />
                            <span className='padding-10-left'><b>Apavu izmērs</b></span>
                        </li>
                        <li>
                            <input type="checkbox" name="showClothingSize" checked={showClothingSize} onChange={(e) => setShowClothingSize(e.target.checked)} />
                            <span className='padding-10-left'><b>Apģērba izmērs</b></span>
                        </li>
                        <li>
                            <input type="checkbox" name="showPhone" checked={showPhone} onChange={(e) => setShowPhone(e.target.checked)} />
                            <span className='padding-10-left'><b>Telefons</b></span>
                        </li>
                        <li>
                            <input type="checkbox" name="showLanguages" checked={showLanguages} onChange={(e) => setShowLanguages(e.target.checked)} />
                            <span className='padding-10-left'><b>Valodas</b></span>
                        </li>
                        <li>
                            <input type="checkbox" name="showAge" checked={showAge} onChange={(e) => setShowAge(e.target.checked)} />
                            <span className='padding-10-left'><b>Vecums</b></span>
                        </li>
                    </ul>
                </div>
            </form>

            <div className="flex column gap-10 padding-20-top">
                <span>Izvēlētās personas:</span>
                <SelectedPeopleGrid people={people} removeSelection={removeSelection} />
            </div>

            <div className="flex padding-10-top">
                <button className='button-basic' onClick={handleSubmit}>Izveidot Projektu</button>
            </div>
        </div>
    )
}

export default NewProjectForm;
