import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValues } from '../../../features/classifiers/classifierSlice';
import ValueDropdown from './ValueDropdown';
import SelectedInputs from './SelectedInputs';

function InputTagSelection({ name, placeholder, setValues, editValues }) {
  const dispatch = useDispatch();
  const { values } = useSelector((state) => state.classifier);
  const { all_values } = useSelector((state) => state.person);

  const [categoryValues, setCategoryValues] = useState([]);
  const [originalCategoryValues, setOriginalCategoryValues] = useState([]);

  const [selectedValue, setSelectedValue] = useState('');

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesTags, setSelectedValuesTags] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (editValues) {
      const names = editValues.values.split(', ');
      const tags = editValues.tags.split(', ');
      setSelectedValues(names);
      setSelectedValuesTags(tags);
    }
  }, [editValues]);

  useEffect(() => {
    if(!all_values || all_values.length === 0){
    const filteredValues = (values || []).filter(value => !selectedValuesTags.includes(value.tag));
    setCategoryValues(filteredValues);
    setOriginalCategoryValues(filteredValues);
    } else {
      const filteredValues = (all_values || []).filter(value => !selectedValuesTags.includes(value.tag));
      setCategoryValues(filteredValues);
      setOriginalCategoryValues(filteredValues);
    }
  }, [values, all_values, selectedValuesTags]);

  const handleValueChange = (e) => {
    const inputValue = createTag(e.target.value);
    setSelectedValue(e.target.value);

    const filteredOptions = originalCategoryValues.filter((value) =>
      value.tag.slice(0, inputValue.length) === inputValue
    );

    setCategoryValues(filteredOptions);
  };

  const saveValues = (names,tags) => {
    const value_tags = tags.join(', ');
    const values = names.join(', ');
    setValues({
      category: name,
      values:values,
      tags:value_tags
    });
  }

  const triggerClassifier = () => {
    if(!all_values || all_values.length === 0){
      dispatch(getValues());
    }
    setSelectedValue('');
    setShowDropdown(true);
  };

  const createTag = (name) => {
    let lowercaseName = name.toLowerCase();
    let normalized_name = lowercaseName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let tag = normalized_name.replace(/[^a-z0-9]+/g, "-");
    return tag;
  };

  const handleValueSelect = (value) => {
    const tag = createTag(value);
    const all_value_tags = [...selectedValuesTags, tag];
    const all_value_names = [...selectedValues, value];
    setSelectedValues(all_value_names);
    setSelectedValuesTags(all_value_tags);
    saveValues(all_value_names, all_value_tags);
    setSelectedValue('');
    setShowDropdown(false);
  };
  
  const handlePressEnter = () => {
    if (selectedValue) {
      const tag = createTag(selectedValue);
      const all_value_tags = [...selectedValuesTags, tag];
      const all_value_names = [...selectedValues, selectedValue];
      setSelectedValues(all_value_names);
      setSelectedValuesTags(all_value_tags);
      saveValues(all_value_names, all_value_tags);
      setSelectedValue('');
      setShowDropdown(false);
    }
  };

  const handleRemoveSelectedValue = (value) => {
    const tag = createTag(value);
    const updatedValueTags = selectedValuesTags.filter((val) => val !== tag);
    const updatedValues = selectedValues.filter((val) => val !== value);
    setSelectedValuesTags(updatedValueTags);
    setSelectedValues(updatedValues);
    saveValues(updatedValues, updatedValueTags);
  };

  return (
    <div className='input-tag-selection'>
      <div className="dropdown-container" ref={dropdownRef}>
        <input
          type="text"
          name={name}
          className='basic-input'
          placeholder={placeholder}
          onClick={() => triggerClassifier()}
          value={selectedValue}
          onChange={handleValueChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handlePressEnter();
            }
          }}
        />
        <ValueDropdown
          showDropdown={showDropdown}
          categoryValues={categoryValues}
          handleValueSelect={handleValueSelect}
          setShowDropdown={setShowDropdown}
        />
      </div>
      <SelectedInputs selected_inputs={selectedValues} onRemoveSelectedValue={handleRemoveSelectedValue}/>
    </div>
  );
}

export default InputTagSelection;
