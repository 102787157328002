import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FaEdit, FaHeart } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { updateLiked } from '../features/projects/projectSlice';

function PublicProjectProfileCard({profile, projectId, setAddComment, setLiked}) {

  const dispatch = useDispatch()

  const handleLike = (person_id) => {
    dispatch(updateLiked({id: projectId, person_id}))
  }

  return (

    <div className='project-profile-card public'>
      <div className="card-icons">
        <FaEdit size={18} className="card-icon left" onClick={() => setAddComment(profile.person_id)} />
        <FaHeart size={18} color={profile.liked ? "red" : ""} className="card-icon right" onClick={() => handleLike(profile.person_id)} />
      </div>


      <Link to={`/projekti/${projectId}/${profile.person_id}`}> 
        <div className="project-card-content padding-10-left padding-10-right padding-10-bottom" > 
          {/* to={`/projects/${projectId}/${profile.person_id}`} THIS WHOLE THING WAS LINK NOT DIV */}
          {profile.thumbnail ? <img src={profile.thumbnail}/> : null}
          <div className="project-profile-card-title padding-20-bottom">
            <h5>{profile.person_name.split(' ')[0]}</h5>
          </div>

          {profile.height &&
            <div className="card-data">
              <p className="m">Garums:</p>
              <p className="m">{profile.height}cm</p>
            </div>
          }

          {profile.age &&
            <div className="card-data">
              <p className="m">Vecums:</p>
              <p className="m">{profile.age}</p>
            </div>
          }

          {profile.shoeSize &&
            <div className="card-data">
              <p className="m">Apavu izmērs:</p>
              <p className="m">{profile.shoeSize}</p>
            </div>
          }

          {profile.clothingSize &&
            <div className="card-data">
              <p className="m">Apģērba izmērs:</p>
              <p className="m">{profile.clothingSize}</p>
            </div>
          }

          {profile.phone &&
            <div className="card-data">
              <p className="m">Telefona numurs:</p>
              <p className="m">{profile.phone}</p>
            </div>
          }

          {/* {profile.languages &&
            <div className="card-data">
              <p className="m">Valodu zināšanas:</p>
              <p className="m">{profile.languages.map(lang => lang.values).join(', ')}</p>
            </div>
          } */}

          {profile.city &&
            <div className="card-data">
              <p className="m">Pilsēta:</p>
              <p className="m">{profile.city.map(city => city.values).join(', ')}</p>
            </div>
          }
        
          
        </div>
      </Link>
    

        {profile.person_comment && 
          <div className="project-profile-card-comment">
            <p>{profile.person_comment}</p>
          </div>
        }

    </div>

  )
}

export default PublicProjectProfileCard