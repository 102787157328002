import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FilterTextInput from './FilterTextInput';
import FilterSelectInput from './FilterSelectInput';
import FilterDoubleInput from './FilterDoubleInput';
import ButtonSecondary from './ButtonSecondary';
import InputTagSelection from './inputs/input_helpers/InputTagSelection';
import TagSelector from './TagSelector';

//import InputSelection from './inputs/input_helpers/InputSelection';

function FilterInput({
    initialGender,
    initialSpeciality,
    initialName,
    initialSurname,
    initialPhone,
    initialEmail,
    initialHeightFrom,
    initialHeightTo,
    initialAgeFrom,
    initialAgeTo,
    initialPaidFor,
    clearFilters,
    initialAllTags,
    initialIdFilter
}) {

    
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    const [gender_filter, setGenderFilter] = useState(initialGender);
    const [speciality_filter, setSpecialityFilter] = useState(initialSpeciality);
    const [paid_for_filter, setPaidForFilter] = useState(initialPaidFor);
    const [name_filter, setNameFilter] = useState(initialName);
    const [surname_filter, setSurnameFilter] = useState(initialSurname);
    const [phone_filter, setPhoneFilter] = useState(initialPhone);
    const [email_filter, setEmailFilter] = useState(initialEmail);
    const [heightFrom_filter, setHeightFromFilter] = useState(initialHeightFrom);
    const [heightTo_filter, setHeightToFilter] = useState(initialHeightTo);
    const [ageFrom_filter, setAgeFromFilter] = useState(initialAgeFrom);
    const [ageTo_filter, setAgeToFilter] = useState(initialAgeTo);
    const [allTags, setAllTags] = useState(initialAllTags ? initialAllTags.split(',').map(Number) : []);
    const [id_filter, setIdFilter] = useState(initialIdFilter);

    const navigateWithParams = () => {
        const params = {
            gender_filter,
            speciality_filter,
            name_filter,
            surname_filter,
            phone_filter,
            email_filter,
            heightFrom_filter,
            heightTo_filter,
            paid_for_filter,
            ageFrom_filter,
            ageTo_filter,
            id_filter,
            allTags_filter: allTags.join(',')
        };
        

        
        const urlParams = new URLSearchParams(
            Object.entries(params)
                .filter(([key, value]) => value !== null && value !== "")
                .map(([key, value]) => [key, encodeURIComponent(value)])
        );

        navigate(`/profiles?${urlParams.toString()}`);
    };

    const callClearFilters = () => {
        clearFilters();
    };


    return (
        <div className="filter-inputs">
            {user?.type === 'admin' && 
            <>
                <div className="filter-inputs-row">
                <FilterSelectInput
                    label="Dzimums"
                    value={gender_filter || "dzimums"}
                    onChange={(value) => setGenderFilter(value)}
                    options={[
                        { value: "dzimums", label: "-" },
                        { value: "man", label: "Vīrietis" },
                        { value: "woman", label: "Sieviete" }
                    ]}
                />

                <FilterSelectInput
                    label="Specialitāte"
                    value={speciality_filter || "specialitate"}
                    onChange={setSpecialityFilter}
                    options={[
                        { value: "specialitate", label: "-" },
                        { value: "talent", label: "Talants" },
                        { value: "actor", label: "Aktieris" },
                        { value: "professional", label: "Profesionālis" }
                    ]}
                />

                <FilterTextInput label="Vārds" name="name" value={name_filter || ""} onChange={setNameFilter} />
                <FilterTextInput label="Uzvārds" name="surname" value={surname_filter || ""} onChange={setSurnameFilter} />
                <FilterTextInput label="Telefons" name="phone" value={phone_filter || ""} onChange={setPhoneFilter} />
            </div>

            <div className="filter-inputs-row">
                <FilterTextInput label="E-pasts" name="email" value={email_filter || ""} onChange={setEmailFilter} />
                <FilterDoubleInput label="Garums" name="height_from" placeholder="No" value={heightFrom_filter || ""} onChange={setHeightFromFilter} name2="height_to" placeholder2="Līdz" value2={heightTo_filter || ""} onChange2={setHeightToFilter} />
                <FilterDoubleInput label="Vecums" name="age_from" placeholder="No" value={ageFrom_filter || ""} onChange={setAgeFromFilter} name2="age_to" placeholder2="Līdz" value2={ageTo_filter || ""} onChange2={setAgeToFilter} />
                <FilterSelectInput
                    label="Apmaksas statuss"
                    value={paid_for_filter || "paid_for"}
                    onChange={setPaidForFilter}
                    options={[
                        { value: "paid_for", label: "-" },
                        { value: 0, label: "Neapmaksāts" },
                        { value: 1, label: "Apmaksāts" },
                        { value: 2, label: "Attaisnots" }
                    ]}
                />
                
                <FilterTextInput label="ID" name="id" value={id_filter || ""} onChange={setIdFilter} />
            </div>
            </>
            }
            
            <div className="padding-10-bottom">
                <label htmlFor="">Meklēt pēc tagiem</label>                
                <TagSelector selectedValues={allTags} setSelectedValues={setAllTags}/>
            </div>
            <div className="filter-inputs-row">
                <div className="filter-input"></div>
                <div className="filter-input"></div>
                <div className="filter-input"></div>
                <ButtonSecondary text="Meklēt" onClick={navigateWithParams} />
                <ButtonSecondary text="Notīrīt" onClick={callClearFilters} />
            </div>
        </div>
    );
}

export default FilterInput;