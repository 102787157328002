import { useEffect } from 'react';
import InputTagSelection from './input_helpers/InputTagSelection';

function PrasmesDati({ features, setValue, transformFeature }) {

  // useEffect(() => {
  //   if(features){
  //     setValue('danceskill', transformFeature(features.danceskill) || '', 'features');
  //     setValue('singskill', transformFeature(features.singskill) || '', 'features');
  //     setValue('musicskill', transformFeature(features.musicskill) || '', 'features');
  //     setValue('sportskill', transformFeature(features.sportskill) || '', 'features');
  //     setValue('swimskill', transformFeature(features.swimskill) || '', 'features');
  //     setValue('driveskill', transformFeature(features.driveskill) || '', 'features');
  //     setValue('languageskill', transformFeature(features.languageskill) || '', 'features');
  //     setValue('otherskill', transformFeature(features.otherskill) || {}, 'features');
  //   }
  // }, [features, setValue, transformFeature]);

  return (
    <div>
      <div className="input-form-container padding-20-top">
        <h2>Prasmes</h2>
        <form className="input-form padding-10-top">
          <div className="form-input">
            <label htmlFor="danceskill">Dejotprasme:</label>
            <InputTagSelection name="danceskill" placeholder="Dejotprasme" setValues={(values) => setValue('danceskill', values, 'features')} editValues={features ? features.danceskill : null} />
          </div>
          <div className="form-input">
            <label htmlFor="singskill">Dziedātprasme:</label>
            <InputTagSelection name="singskill" placeholder="Dziedātprasme" setValues={(values) => setValue('singskill', values, 'features')} editValues={features ? features.singskill : null} />
          </div>
          <div className="form-input">
            <label htmlFor="musicskill">Mūzikas instrumenti:</label>
            <InputTagSelection name="musicskill" placeholder="Mūzikas instrumenti" setValues={(values) => setValue('musicskill', values, 'features')} editValues={features ? features.musicskill : null} />
          </div>
          <div className="form-input">
            <label htmlFor="sportskill">Sporta veidi:</label>
            <InputTagSelection name="sportskill" placeholder="Sporta veidi" setValues={(values) => setValue('sportskill', values, 'features')} editValues={features ? features.sportskill : null} />
          </div>
          <div className="form-input">
            <label htmlFor="swimskill">Peldētprasme (no 1-10):</label>
            <InputTagSelection name="swimskill" placeholder="Peldētprasme" setValues={(values) => setValue('swimskill', values, 'features')} editValues={features ? features.swimskill : null} />
          </div>
          <div className="form-input">
            <label htmlFor="driveskill">Transportlīdzekļa vadīšana:</label>
            <InputTagSelection name="driveskill" placeholder="Transportlīdzekļa vadīšana" setValues={(values) => setValue('driveskill', values, 'features')} editValues={features ? features.driveskill : null} />
          </div>
          <div className="form-input">
            <label htmlFor="languageskill">Valodu zināšanas:</label>
            <InputTagSelection name="languageskill" placeholder="Valodu zināšanas" setValues={(values) => setValue('languageskill', values, 'features')} editValues={features ? features.languageskill : null} />
          </div>
          <div className="form-input">
            <label htmlFor="otherskill">Hobiji:</label>
            <InputTagSelection name="otherskill" placeholder="Hobiji" setValues={(values) => setValue('otherskill', values, 'features')} editValues={features ? features.otherskill : null} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default PrasmesDati;
