import {Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './pages/private/Login';
import Header from './components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Navigate } from 'react-router-dom';
import Profiles from './pages/private/Profiles';
import Profile from './pages/private/Profile';
// import Input from './pages/private/Input';
import Projects from './pages/private/Projects';
import Project from './pages/private/Project';
import PublicProfile from './pages/private/PersonGallery';
import Logout from './components/Logout';
import Home from './pages/public/Home';
// import InputForm from './pages/public/InputForm';
import PublicProject from './pages/public/PublicProject';
import PublicProjects from './pages/public/PublicProjects';
// import UnverifiedProfiles from './pages/private/UnverifiedProfiles';
import ApplyChoice from './pages/public/ApplyChoice';
// import Pieteikums from './pages/Pieteikums';
import CastingForm from './pages/private/EditPerson';
import ClassifierCategories from './pages/private/ClassifierCategories';
import EditClassifier from './pages/private/EditClassifier';
import AddPerson from './pages/public/AddPerson';
import ProjectPerson from './pages/public/ProjectPerson';
import PublicPerson from './pages/public/PublicPerson';

function App() {
  return (
    <>
      <Router>
        <div className='container'>
        <Header />  
          <Routes>
            <Route path='/' element={<Home />} />
            
            <Route path='/profiles' element={<Profiles />} />
            <Route path='/producents' element={<Profiles producents={true}/>} />
            <Route path='/unverified' element={<Profiles unverified={true}/>} />

            <Route path='/logout' element={<Logout />} />
            <Route path='/login' element={<Login />} />

            <Route path='/galerija/:profileId' element={<PublicProfile />} />

            <Route path='/projekti' element={<Projects />} />
            <Route path='/projekti/:projectId' element={<Project />} />
            <Route path='/projekti/:projectId/:profileId' element={<ProjectPerson />} />

            <Route path='/apply' element={<ApplyChoice />} />

            <Route path='/people/:profileId' element={<PublicPerson />} />

            {/* <Route path='/projekti/public/' element={<PublicProjects />} /> */}
            <Route path='/projekti/public/:projectId' element={<PublicProject />} />

            <Route path='/pieteikums' element={<AddPerson />} />
            
            <Route path='/pieteikums/:profileId' element={<CastingForm />} />

            {/* <Route path='/people/:profileId' element={<PublicPerson />} /> */}

            <Route path='/classifiers' element={<ClassifierCategories />} />
            <Route path='/classifiers/:category' element={<EditClassifier />} />

            <Route path='*' element={<Navigate to="/" />} />
          </Routes>
        </div>
      
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
