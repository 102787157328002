import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import personService from './personService'
import { toast } from 'react-toastify'

const initialState = {
  person: null,
  people: [],
  pages: 0,
  all_values: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get single person private
export const getSinglePerson = createAsyncThunk(
  'people/:id',
  async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await personService.getPerson(token, id)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get single person public
export const getSinglePersonPublic = createAsyncThunk(
  'people/public/:id',
  async (id, thunkAPI) => {
    try {
        return await personService.getPersonPublic(id)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all people private
export const getPeople = createAsyncThunk(
  'people/',
  async ({ page, filters }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const user_type = thunkAPI.getState().auth.user.type

      var response = null
      if(user_type === 'admin'){
        response = await personService.getPeople(token, page, filters)
      } else {
        response = await personService.getPeoplePublic(page, filters)
      }
       
      return response
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all people private
export const getPeoplePublic = createAsyncThunk(
  'people/public',
  async ({ page, filters }, thunkAPI) => {
    try {
      return await personService.getPeoplePublic(page, filters)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get all people private
export const getUnverifiedPeople = createAsyncThunk(
  'people/unverified',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await personService.getUnverifiedPeople(token)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// add new person
export const addPerson = createAsyncThunk(
  'people/add',
  async (data, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.user.token
      return await personService.addPerson(data)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendPics = createAsyncThunk(
  'people/sendmail',
  async (id, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.user.token
      return await personService.sendPics(id)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// edit person
export const editPerson = createAsyncThunk(
  'people/edit',
  async (data, thunkAPI) => {
    try {
      // const token = thunkAPI.getState().auth.user.token
      return await personService.editPerson(data)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete person
export const deletePeople = createAsyncThunk(
  'people/delete',
  async (ids, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await personService.deletePeople(token, ids)
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      state.person = null
      state.people = []
      state.pages = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSinglePerson.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSinglePerson.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.person = action.payload
      })
      .addCase(getSinglePerson.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.person = null
      })

      .addCase(getSinglePersonPublic.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSinglePersonPublic.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.person = action.payload
      })
      .addCase(getSinglePersonPublic.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.person = null
      })

      .addCase(getPeople.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPeople.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.people = action.payload.data
        state.pages = action.payload.pages
        state.all_values = action.payload.values
      })
      .addCase(getPeople.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.people = []
        state.pages = 0
      })

      .addCase(getUnverifiedPeople.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUnverifiedPeople.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.people = action.payload.data
        state.pages = action.payload.pages
      })
      .addCase(getUnverifiedPeople.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.people = []
        state.pages = 0
      })
      
      .addCase(addPerson.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addPerson.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true;
        if(action.payload.approved){
          state.people = [
            action.payload,
            ...(state.people.slice(0, -1)),
          ]
        }
        action.payload.approved ? toast.success('Profils pievienots!') : toast.success('Profils pievienots un mēs to drīz apstrādāsim!');
      })
      .addCase(addPerson.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.person = null
        toast.error('Neizdevās pievienot profilu');
      })

      .addCase(editPerson.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editPerson.fulfilled, (state, action) => {
        state.isSuccess = true;
        toast.success('Profils saglabāts!');
      })
      .addCase(editPerson.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.person = null
        toast.error('Neizdevās saglabāt izmaiņas');
      })

      .addCase(sendPics.pending, (state) => {
        state.isLoading = true
      })
      .addCase(sendPics.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true;
        console.log(state.person)
        console.log(action.payload)
        // state.person.images_sent = 1;
        
        window.location.reload();
        toast.success('E-pasts nosūtīts');
      })
      .addCase(sendPics.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        toast.success('Neizdevās nosūtīt bildes');
      })

      .addCase(deletePeople.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePeople.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.people = state.people.filter(person => !action.payload.includes(person.id));
        localStorage.removeItem('selectedPeopleUnverified');
        toast.success('Izvēlētie profili dzēsti');
      })
      .addCase(deletePeople.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.person = null
        toast.error('Neizdevās dzēst profilus');
      })
      .addCase(getPeoplePublic.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPeoplePublic.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.people = action.payload.data
        state.pages = action.payload.pages
      })
      .addCase(getPeoplePublic.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.people = []
        state.pages = 0
      })
      
  },
})

export const { reset } = personSlice.actions
export default personSlice.reducer
