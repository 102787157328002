import { useEffect } from 'react';
import InputTagSelection from './input_helpers/InputTagSelection';

function DarbsIzglitibaDati({ person, features, setValue, transformFeature }) {

  // useEffect(() => {
  //   if(person){
  //     setValue('current_occupation', person.current_occupation || '', 'person');
  //     setValue('workplace', person.workplace || '', 'person');
  //   }
  // }, [person, setValue]);

  // useEffect(() => {
  //   if(features){
  //     setValue('educational_institution', transformFeature(features.educational_institution) || {}, 'features');
  //     setValue('learned_profession', transformFeature(features.learned_profession) || {}, 'features');
  //     setValue('degree', transformFeature(features.degree) || {}, 'features');
  //     setValue('workplaces', transformFeature(features.workplaces) || {}, 'features');
  //   }
  // }, [features, setValue, transformFeature]);

  return (
    <div>
      <div className="input-form-container padding-20-top">
        <h2>Darbs un Izglitiba</h2>
        <form className="input-form padding-10-top">
          <div className="form-input">
            <label htmlFor="educational_institution">Mācību iestāde:</label>
            <InputTagSelection name="educational_institution" placeholder="Mācību iestāde" setValues={(values) => setValue('educational_institution', values, 'features')} editValues={features ? features.educational_institution : null} />
          </div>
          <div className="form-input">
            <label htmlFor="learned_profession">Apgūtā profesija:</label>
            <InputTagSelection name="learned_profession" placeholder="Apgūtā profesija" setValues={(values) => setValue('learned_profession', values, 'features')} editValues={features ? features.learned_profession : null} />
          </div>
          <div className="form-input">
            <label htmlFor="degree">Klase vai līmenis:</label>
            <InputTagSelection name="degree" placeholder="Klase vai līmenis" setValues={(values) => setValue('degree', values, 'features')} editValues={features ? features.degree : null} />
          </div>
          <div className="form-input">
            <label htmlFor="current_occupation">Pašreizējā nodarbošanās:</label>
            <select defaultValue={person ? person.current_occupation : ''} placeholder="Pašreizējā nodarbošanās" onChange={e => setValue('current_occupation', e.target.value, 'person')}>
              <option value="Cita" disabled>Pašreizējā nodarbošanās</option>
              <option value="Skolnieks">Skolnieks</option>
              <option value="Students">Students</option>
              <option value="Strādājošs">Strādājošs</option>
              <option value="Bezdarbnieks">Bezdarbnieks</option>
              <option value="Mākslinieks">Mākslinieks</option>
              <option value="Cits">Cits</option>
            </select>
          </div>
          <div className="form-input">
            <label htmlFor="workplace">Darba vieta/s:</label>
            <input defaultValue={person ? person.workplace : ''} className="workplace" type="text" name="workplace" placeholder="Darba vieta/s" onChange={e => setValue('workplace', e.target.value, 'person')} />
          </div>
          <div className="form-input">
            <label htmlFor="workplaces">Profesija:</label>
            <InputTagSelection name="workplaces" placeholder="Profesija" setValues={(values) => setValue('workplaces', values, 'features')} editValues={features ? features.workplaces : null} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default DarbsIzglitibaDati;
