import Spinner from '../../components/Spinner';
import sieviesubasketbols from '../../images/sieviesubasketbols.webp';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

function Home() {
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state.person);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="home-page">
        <div className="home-landing">
            <div className="landing-info">
                <h4>Casting Bridge</h4>
                <p className='padding-20-top'>CASTING BRIDGE ir radoša un atbildīga aktieru atlases komanda, kas kopš 2011. gada nodrošina profesionālu aktieru un masu skatu organizēšanu, administrēšanu un koordinēšanu gan audiovizuālo projektu sagatavošanas posmā, gan uz filmēšanas laukuma. Citiem vārdiem - mūsu darbs sākas no idejām par konkrētajai lomai piemērotu aktieri un turpinās līdz pat brīdim, kad filmēšana ir beigusies. CASTING BRIDGE izveidotāja, dzinējspēks un radošā direktore ir pieredzes bagātā aktieru atlases režisore Gunita Groša.</p>
                <p className='padding-20-top'>Mūsu aktieru un talantu datu bāzē ir apkopoti Latvijas profesionālie aktieri un statisti, kā arī visdažādākā vecuma un izskata cilvēki, kuri vēlas piedalīties audiovizuālos projektos – filmās, reklāmās, mūzikas klipos un seriālos. CASTING BRIDGE elektroniskā datu bāze tiek nemitīgi papildināta un ir pieejama aktieru atlases režisoru, režisoru, producentu un fotogrāfu profesionālai lietošanai. </p>
                <p className='padding-20-top'>Mēs ar azartu un smaidu darām visu, ko vien iespējams darīt kastinga jomā!</p>
                <button style={{cursor: "pointer", marginTop: 10}} className="button" onClick={() => navigate("/apply")}>Pieteikties</button>
​            </div>
        </div>
    </div>
  )
}

export default Home
  