import { useState, useRef } from 'react';
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';

function MultipleImages({ setValue, gallery }) {
  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const selectFiles = () => {
    fileInputRef.current.click();
  };

  const onFileSelect = (event) => {
    const files = event.target.files;
    if (files.length === 0) return;

    processFiles(Array.from(files));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    processFiles(Array.from(files));
  };

  const processFiles = (files) => {
    const uploads = files
      .filter(file => file.type.split('/')[0] === 'image')
      .map(file => {
        const imageName = (file.name + v4()).toUpperCase();
        return uploadImage(file, imageName);
      });

    Promise.all(uploads).then((newImages) => {
      const updatedImages = [...gallery, ...newImages];
      setImages(updatedImages);
      setValue('images', updatedImages, 'person');
    });
  };

  const uploadImage = (imageFile, imageName) => {
    const imagePath = `cb_images/${imageName}`;
    const newImageRef = ref(storage, imagePath);
    const uploadTask = uploadBytesResumable(newImageRef, imageFile);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const img = new Image();
            img.onload = function () {
              const galleryImage = {
                src: downloadURL,
                width: this.width,
                height: this.height,
                isSelected: false,
                name: imagePath.split('/')[1],
              };

              resolve(galleryImage);
            };
            img.src = downloadURL;
          }).catch(reject);
        }
      );
    });
  };

  return (
    <div className="input-form-container padding-20-top">
      <h2>Pievienot Bildes Galerijai</h2>
      <div className="multi-image-card">
        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
          {isDragging ? (
            <span className="select">Drop Images here</span>
          ) : (
            <>Iemet bildes te, vai <span className="select" role="button" onClick={selectFiles}>Izvēlēties</span></>
          )}
          <input type="file" className="file" multiple ref={fileInputRef} onChange={onFileSelect} />
        </div>
      </div>
    </div>
  );
}

export default MultipleImages;
